/*For all*/

a, div {
    letter-spacing: 0.025rem;
    color: initial;
}

.display-bottom-blur {
    width: 100%;
    position: fixed;
    height: 40vh;
    bottom: 0;
    background: rgba(84, 84, 84, 0.25);
    backdrop-filter: blur(15px);
    z-index: 30;
}

.fair-pricing-discount-section-container {
    margin-bottom: 128px;
}

.navbar-top-container {
    background-color: #FFFFFF;
    width: 100%;
    overflow: hidden;
}

.navbar-top-container-narrow {
    background-color: #FFFFFF;
    width: 100%;
    overflow: hidden;
    position: fixed;
}

.navbar-top-company-logo {
    font-family: 'Montserrat';
    font-weight: 400;
    float: left;
}

.navbar-top-company-logo-contianer {
    width: auto;
    overflow-y: auto;
}

.navbar-top-company-name {
    text-transform: uppercase;
    float: left;
    letter-spacing: 0.1rem;
}

.navbar-top-company-name-large-font {
    font-size: 1.4rem;
}

.navbar-top-company-name {
    font-size: 1.3rem;
}

.navbar-top-button {
    float: left;
}

.product-list-web-container {
    width: 24.18vw;
    height: 4.1vw;
    float: left;
    color: #72696A;
    font-family: Montserrat;
    letter-spacing: 0.33px;
    line-height: 1.32vw;
    text-align: right;
    position: absolute;
    margin: 1.39vw 1.39vw 1.39vw 18.36vw;
}

.navbar-top-web-language-selected {
    font-weight: 500;
}

.navbar-top-cart-icon {
    width: 100%;
    overflow: auto;
}

.navbar-top-button a {
    color: #F59276;
    font-size: 1.11vw;
    line-height: 1.597vw;
}

#navbar-top-start-designing-button a:hover {
    color: white;
}

a div:hover {
    transition: background-color 0.5s ease;
}

a:hover {
    transition: background-color 0.5s ease;
    /*text-decoration-color: #F59276;*/
}



.navbar-top-button-inner-layer {
    padding-top: 1.01vw;
    padding-bottom: 1.01vw;
    color: #F59276;
    font-weight: 400;
    border: solid 0.21vw #f59276;
    cursor: pointer;
}

.navbar-top-button-inner-layer:hover {
    color: white;
}

#navbar-top-start-designing-button:hover {
    text-decoration: none;
    color: white;
    background: #f59276;
}

.navbar-top-button:hover {
    color: white;
}

.navbar-top-button a:hover {
    color: white;
}

.navbar-top-company-description-br {
    display: none;
}

.navbar-top-company-name-logo-image {
    width: 100%;
    overflow: auto;
}

.primary-matting-chevron {
    cursor: pointer;
}

.mat-board-size-selector-header {
    padding: 0.5rem 0 0 0;
}

.question-mark-icon-container {
    width: auto;
    margin: 0;
    float: right;
    cursor: pointer;
}

.question-mark-icon {
    margin: 0.2rem 0 0 0.2rem;
}

.canvas-app-rendering-option {
    padding-top: 0;
}

.canvas-app-rendering-wall-layer {
    width: 100%;
    overflow-y: auto;
    background-color: mintcream;
}

.canvas-app-rendering-expand-button {
    cursor: pointer;
}

.canvas-app-rendering-expand-button-script {
    float: left;
    padding: 0.25rem;
}




.landing-page-banner {
    margin: 0;
}

.navbar-top-company-description {
    width: auto;
    overflow: auto;
    float: left;
    font-size: 0.8rem;
    margin: 0;
    font-weight: 500;
    color: #f59276;
    margin-left: 0.5rem;
    line-height: 1.5;
    margin-top: 0.9rem;
    text-transform: lowercase;
}

.navbar-top-gallery-container {
    width: auto;
    float: left;
    margin-top: 1.5rem;
    margin-left: 1rem;
    font-size: 1.3rem;
    color: #f59276;
    line-height: 1;
}

.navbar-top-gallery-container span {
    font-size: 0.8rem;
    text-transform: lowercase;
}

.navbar-top-gallery-web-button a,
.navbar-top-contact-web-button a,
.product-list-web-container a,
.navbar-top-web-language a {
    color: #72696A;
}

.navbar-top-web-cart, .navbar-top-web-cart-icon {
    cursor: pointer;
}

.support-v2-dropdown-button {
    width: 100%;
}

.support-v2-dropdown-button:hover {
    text-decoration: underline;
}

.cart-item-count-container {
    width: 20px;
    height: 20px;
    background: #3F5C6B;
    border-radius: 100%;
    margin-top: -45px;
    margin-left: 23px;
    z-index: 50;
    position: absolute;
}

.cart-item-count {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: white;
    width: 20px;
    height: 20px;
    padding: 1px;
}

.bag-v2 {
    cursor: pointer;
}

.bag-v2-narrow {
    cursor: pointer;
}

@media screen and (min-width: 0px) and (max-width: 1100px) {

    .support-v2-dropdown-button {
        margin-bottom: 24.5px;
    }

    .support-v2-dropdown {
        padding: 24.5px 0 0 24.5px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: #3B3B3B;
        text-transform: none;
        background: white;
        z-index: 100;
    }




    .start-designing-page-container {
        margin-top: 4rem;
        margin-bottom: 4rem;
        border-top: 0.05rem #f59276 solid;
    }

    /*.navbar-top-company-description {*/
    /*    font-size: 0.7rem;*/
    /*    margin: 0;*/
    /*    padding: 0;*/
    /*}*/

    /*.landing-page-banner {*/
    /*    font-size: 2.0778vw;*/
    /*}*/

    /*.mobile-no-display {*/
    /*    display: none;*/
    /*}*/

    .mat-board-size-selector-header {
        font-size: 1rem;
    }

    .question-mark-icon {
        width: 0.8rem;
    }


    .product-list-web-container {
        display: none;
    }

    .navbar-top-button {
             display: none;
    }


    .navbar-top-company-logo {
        width: 80%;
    }

    .question-mark-icon {
        width: 0.8rem;
    }

    /*.navbar-top-button-groups {*/
    /*    width: 100%;*/
    /*    overflow-y: auto;*/
    /*}*/
}

/*Normal Laptop and External Monitors*/







