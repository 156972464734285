/*Mobile, Tablet, Web Half Screen*/

@media screen and (min-width: 0px) and (max-width: 769px) {

    .promotion-messages {
        width: 100%;
        overflow: auto;
        color: red;
        font-weight: 600;
        text-align: center;
        font-size: 16px;
    }



    .landing-page-transition-v2 {
        overflow-y: auto;
    }

    .notice-bar-version2-event {
        position: static;
        width: 100%;

        background: black;
        overflow-y: auto;

        left: 0.83px;
        top: 0px;


        flex: none;
        order: 0;
        flex-grow: 0;
        padding: 0px 0px;
    }

    .notice-bar-version2 {
        position: static;
        width: 100%;
        /*height: 32px;*/
        background: #3F5C6B;


        left: 0.83px;
        top: 0px;


        flex: none;
        order: 0;
        flex-grow: 0;
        padding: 0px 0px;
    }

    .landing-page-bottom-text-highlight {
        font-weight: 700;
        font-size: 24px;
    }

    .what-is-mat-desc-v2 {
        width: 248px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        margin-left: calc(50% - 124px);
        text-align: left;
        margin-bottom: 64px;
    }

    .benefits-summary-container {
        width: 248px;
        margin-left: calc(50% - 124px);
        overflow: auto;
    }

    .benefits-summary-component-img {
        width: 82px;
        height: 73px;
        margin: 37px 83px 16px 83px;
    }

    /*.mat-transition-container {*/
    /*    width: 100%;*/
    /*    height: 180px;*/
    /*    overflow: hidden;*/
    /*}*/

    .benefits-page-container {
        width: 100%;
        overflow: auto;
        padding: 64px 0;
    }
    
    #how-it-works-video1, #how-it-works-video2 {
        width: 100%;
        overflow: auto;
        margin: 0;
    }

    #how-it-works-video1 {
        margi-top: 32px;
    }

    #how-it-works-video2 {
        margi-top: 64px;
    }

    .landing-page-button-standard-v2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: static;
        width: 194px;
        height: 52px;
        background: #3B3B3B;
        border-radius: 30px;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        flex: none;
        order: 2;
        flex-grow: 0;
        color: white;
        cursor: pointer;
        margin: 64px calc( 50% - 97px) 0;
        text-align: center
    }

    #how-it-works-substeps-3 {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    #how-it-works-substeps-1, #how-it-works-substeps-2 {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        margin-top: 24px;

    }


    .how-it-works-header-left, how-it-works-header-right {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;
    }

    .how-it-works-step2-desc-v2 {
        width: 260px;
        margin: 32px calc( 50% - 124px);
        overflow: auto;
    }

    #how-it-works-image1, #how-it-works-image2 {
        margin: 0;
        padding: 0;
        width: 100%;
        overflow: auto;
    }

    .how-it-works-subheader-right u {
        text-decoration: none;
    }
    .mat-transition-container {
        width: auto;
        overflow: auto;
        margin: 10px;
    }

    .howItWorks-transition-img-ctr {
        width: 100%;
        height: auto;
        float: left;
    }

    .howItWorks-transition-img-left, .howItWorks-transition-img-right {
        width: 48%;
        height: auto;
        float: left;
        margin-top: 20px;
    }

    .review-transition-widget {
        width: calc(100% - 50px);
        overflow: hidden;
        margin: 25px;

    }

    .testimonial-photo-container {
        width: 100%;
        overflow: auto;
        float: left;
    }



    .review-transition-center {
        width: 100%;
        overflow: auto;
        float: left;
        border-radius: 25px;
        padding-bottom: 33px;
        box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
        margin-bottom: 24px;
        background: #FFF;
    }



    .testimonial-photo {
        width: 268px;
        height: 310px;
        object-fit: cover;
        float: left;
        margin: 33px 27px 24px 33px;
    }

    .review-transition-logo-container {
        width: 124px;
        margin: 28px calc( 50% - 62px );
        height: 45px;
    }

    .testimonial-desc {
        width: 270px;
        height: auto;
        padding: 0 36px 36px 36px;
        float: left;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        color: #3B3B3B;
    }

    .review-transition-widget-dials-container {
        width: 140px;
        overflow: auto;
        display: inline-block;
        float: left;
        margin: 20px 16px 20px 16px;
    }

    .review-transition-widget-dials {
        display: flex;
        align-items: center;
        gap: 8px;
        overflow: auto;
    }

    .testimonial-more-review-button {
        width: 100%;
        text-align: center;
        text-decoration: underline;
        margin-top: 24px;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
    }

    .progress-indicator {
        width: 272px;
        overflow: auto;
        margin-left: calc( 50% - 136px);
    }



    .dial-selected {
        background: #3B3B3B;
    }

    .dial {
        width: 5px;
        height: 5px;
        border-radius: 5px;
        border: 1px #3B3B3B solid;
        float: left;
    }

    .arrow-button {
        width: 50px;
        height: 50px;
        float: left;
    }

    .arrow-button:hover {
        cursor: pointer;
    }

    .howItWorks-transition-img-left {
        margin-left: 2%;
    }

    .howItWorks-transition-img-right {
        margin-right: 2%;
    }

    #arrow-button-left {
        display: flex;
    }

    #arrow-button-right {
        display: flex;
    }



    .benefits-summary-component-lead {
        color: #3B3B3B;
        text-align: center;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 141.176% */
        letter-spacing: 1.7px;
    }

    .benefits-summary-component-text {
        color: #3B3B3B;

        text-align: center;
        font-family: Montserrat;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 133.333% */
        letter-spacing: 1.5px;
    }

    .mat-transition-img-left {
        width: 28%;
        height: 166px;
        object-fit: cover;
        padding: 1%;
        float: left;
    }

    .mat-transition-img-right {
        width: 65%;
        height: 166px;
        object-fit: cover;
        padding: 1% 1% 1% 0;
    }



    .customizable-image-container {
        width: 248px;
        margin: 24px calc(50% - 124px);
        overflow: hidden;
    }

    .customizable-image-left, .customizable-image-ctr {
        width: 40%;
        float: left;
        margin-right: 2.5%;
        height: 97px;
        object-fit: fill;

    }

    .customizable-image-right {
        width: 12.5%;
        height: 97px;
        float: left;
        object-fit: fill;
    }

    .customizable-container {
        width: 100%;
        overflow: auto;
        background: #FAF2ED;
        padding: 64px 0px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: flex;
        text-align: center;
    }



    .customizable-head {
        width: 248px;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 36px */
        letter-spacing: 2.4px;
        text-transform: uppercase;
        text-align: left;
        margin-left: calc( 50% - 124px);
    }




    .landing-page-standard-right-v2 {
        width: 330px;
        overflow: auto;
        margin: 24px calc( 50% - 165px) 0;
    }

    .landing-page-standard-left-v2 {
        width: 250px;
        overflow: auto;
        margin: 140px calc(50% - 125px) 0 calc(50% - 125px);
    }

    .landing-page-faq {
        width: 90%;
        float: left;
        line-height: 1.5;
        margin-bottom: 64px;
        margin-left: 5%;
    }

    .event-message-version2 {
        width: 100%;
        overflow: auto;
        margin: 0;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 600;
        line-height: 150%; /* 24px */
        padding: 4px 0;
    }

    .notice-bar-version2-fr {
        position: static;
        width: 100%;
        height: 32px;
        left: 0.83px;
        top: 0px;
        background: #3F5C6B;
        /*background: black;*/
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 0px;
    }

    .event-message-version2-fr {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        height: 50px;
        margin-top: 0;

        /* Body */

        font-family: Montserrat;
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 24px */

        align-items: center;
        text-align: center;

        /* Secondary/White */

        font-weight: 600;

        color: #FFFFFF;
        padding-top: 4px;
    }

    .inspiration-post-col-photo {
        width: 100%;
        /*height: 352px;*/
        margin-top: -5px;
        object-fit: cover;
    }

    .inspiration-post-col-photo-landing-page {
        width: 247px;
        height: 309px;
        object-fit: cover;
    }

    .inspiration-post-moblet {
        width: 100%;
        height: auto;
    }

    .instagrom-scrollerpost-container {
        width: auto;
        float: left;
        margin-right: 32px;
    }



    .landing-page-review-transition-ending-button-v3 {
        width: 90%;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        color: #3B3B3B;
        margin: 24px 5%;
    }

    .landing-page-review-transition-ending-header-v3 {
        position: static;
        width: 60%;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 150%;
        align-items: center;
        text-align: left;
        letter-spacing: 0.1em;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0 20% 48px;
        text-transform: uppercase;
    }

    .transition-ig-feed-top-left {
        display: none;
    }

    #satisfaction-guarantee-container {

    }

    .testimonial-subscript {
        width: 100px;
        margin-left: 36.38px;
        float: left;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 19.5px */
        letter-spacing: 1.3px;
        text-transform: uppercase;
    }


    .howItWorks-transition-img-right {
        width: 49%;
        height: 226px;
        object-fit: cover;
        margin-right: 1%;
        float: left;
    }


    .howItWorks-transition-img-left {
        width: 49%;
        height: 226px;
        object-fit: cover;
        margin-left: 1%;
        float: left;

    }

    .transition-ig-feed-bottom-img {
        width: 100%;
        overflow: auto;
        object-fit: cover;
    }

    .instagram-scroller-right {
        width: 248px;
        overflow: auto;
        margin: 0 calc( 50% - 124px) 64px;
    }

    .instagram-scroller {
        width: 248px;
        overflow-x: hidden;
        margin: 64px calc(50% - 124px) 42px;
    }

    .precut-mat-container {
        /*margin-top: 120px;*/
        padding: 5%;
    }


    .instagram-scroller-right-subtitle {
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 20px */
        letter-spacing: 1.6px;
        color: white;
        margin-top: 20px;
    }

    .landing-page-review-transition-ending-v3{
        position: static;
        width: 100%;
        height: auto;


        /* Header 2 */

        font-family: Montserrat;
        font-style: normal;
        font-size: 36px;
        line-height: 46px;
        /* or 128% */

        text-align: center;
        letter-spacing: 0.1em;

        /* Primary/Soft Black */

        color: #3B3B3B;
        padding: 64px calc( 50% - 327.5px);
    }

    .landing-page-review-v2 {
        width: 100%;
        /* height: 286px; */
        float: left;
        text-align: center;
        min-height: 286px;
        overflow: auto;
    }

    .landing-page-review-content-v2 {
        margin: 24px 0;

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 150%;
        margin-bottom: 24px;
        /* or 36px */

        text-align: center;


        /* Primary/Soft Black */

        color: #3B3B3B;
    }

    .landing-page-bottom-cover {
        background: #FAF2ED;
        padding: 64px 0;
        width: 100%;
        overflow: auto;
    }

    .landing-page-bottom {
        text-align: center;
        /* Header 4 */
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 36px */
        letter-spacing: 2.4px;
        text-transform: uppercase;
    }

    .landing-page-bottom-text-img {
        width: 60px;
        overflow: auto;
        margin: 0 calc(50% - 30px);
    }



    .landing-page-bottom-text {
        color: #3B3B3B;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 0.8px;
        text-transform: capitalize;
        width: 330px;
        margin: 0 calc( 50% - 165px) 56px;
    }
    
    .landing-page-bottom-stars {
        width: 180px;
        overflow: auto;
        margin: 32px calc(50% - 90px) 8px;
    }

    .transition-ig-feed-text {
        color: #3B3B3B;
        text-align: center;
        font-family: Montserrat;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 1.3px;
        text-decoration-line: underline;
        text-transform: capitalize;
    }

    .transition-ig-feed-top-right {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 24px */
        text-align: left;
    }

    .landing-page-review-container-v2 {
        width: 90%;
        padding: 128px 5%;
        overflow-y: auto;
        min-height: 286px;
        overflow-x: hidden;
        background: white;
    }

    .transition-ig-feed-container {
        width: calc( 100% - 32px);
        margin: 0 16px;
        overflow: auto;
    }
    .transition-ig-feed-bottom {
        margin-top: 16px;
    }

    .landing-page-total-review-count-v2 {
        margin-top: 24px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .customer-review-image {
        width: 50px;
        height: 50px;
        overflow: auto;
        border-radius: 100%;
        margin-bottom: 10px;
    }

    .landing-page-reviewer-name-v2 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;

    }

    .landing-page-left-arrow-v2, .landing-page-right-arrow-v2  {
        width: 10px;
        height: 18px;
        position: absolute;
        cursor: pointer;
    }

    .landing-page-arrow {
        width: 10px;
        height: 18px;
    }

    .landing-page-left-arrow-v2 {
        /*margin-top: 79px;*/
        /*margin-left: 9.79%;*/
        float: left;
        cursor: pointer;
        left: 10%;
    }

    .landing-page-right-arrow-v2 {
        /*margin-top: 79px;*/
        /*margin-left: 87.65%;*/
        float: right;
        cursor: pointer;
        right: 10%;
    }


    #landing-page-button-standard-v2-moblet {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        align-items: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        background: #3B3B3B;
        border-radius: 30px;
        width: 144px;
        padding: 16px 24px;
        text-align: center;
        margin-left: calc( 50% - 96px);
        margin-bottom: 64px;
        text-decoration: none !important;
    }

    #how-it-works-header-left-moblet {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 44px;
        text-align: center;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin-bottom: 24px;
        width: 100%;
        overflow: auto;
    }

    #how-it-works-subheader-left-moblet {
        position: static;
        width: 310px;
        height: 48px;
        left: 24.5px;
        top: 112px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #3B3B3B;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 24px calc(50% - 155px) 32px;
    }

    #how-it-works-header-right-moblet {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 44px;
        text-align: center;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 64px 0px 24px 0;
    }

    #how-it-works-step2-desc-v2-moblet {
        width: 100%;
        overflow: auto;
    }

    #how-it-works-header-right-moblet {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 44px;
        text-align: center;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 64px 0px 24px 0;
    }

    .landing-page-transition-text-v2 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;
        padding: 80.5px 18px;
    }

    .how-it-works-subheader-right {
        margin-top: 24px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
    }

    #how-it-works-subheader-right-moblet {
        width: 296px;
        overflow: auto;
        text-align: center;
        margin: 0 calc(50% - 148px) 32px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
    }

    .landing-page-review-transition-ending-header-v2 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 32px 0px;
        background: #D6D1BA;
    }

    .landing-page-review-transition-ending-header-v2 {
        width: 328px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        background: #D6D1BA;
        padding: 64px 0 32px 0;
        margin: 0 calc(50% - 164px);
    }

    .landing-page-review-transition-ending-v2{
        width: 100%;
        padding-bottom: 64px;
        background: #D6D1BA;
    }

    .landing-page-review-transition-ending-button-v2 {
        width: 210px;
        overflow: auto;
        text-align: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        align-items: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        /* margin: 0px 10px; */
        padding: 16px 24px;
        background: #FFFFFF;
        border-radius: 30px;
        margin: 0 calc(50% - 129px);
        text-decoration: none !important;
    }

    .how-it-works-steps-container-v2 {
        padding-bottom: 64px;
        overflow-x: hidden;
    }


    .blog-type-2-pinterest-pin {
        width: 90%;
        margin: 5%;
    }

    .blog-type-2-paragraph-image-subscript {
        width: 90%;
        margin: 0 5% 5vh 5%;
        line-height: 7.8125vw;
        font-size: 5vw;
        color: #72696a;
        font-weight: 400;
        font-style: italic;
        text-align: center;
    }

    .blog-type-2-container {
        position: static;
        width: 90%;
        overflow: auto;
        left: 0px;
        top: 116px;
        font-family: Montserrat;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        margin-bottom: 24px;
        margin: 5%;
    }

    .blog-type-2-paragraph {
        width: 90%;
    }

    .blog-type-2-header {
        width: 100%;
        text-align: left;
        letter-spacing: 0.67px;
        margin: 5.55vh 0;
        color: #3B3B3B;
        font-size: 36px;
        line-height: 150%;
        font-family: Montserrat;
        font-style: normal;
    }

    .blog-lead-photo, .blog-type-2-paragraph {
        width: 100%;
        /* font-size: 1.11vw; */
        /* line-height: 1.74vw; */
        /* color: #72696A; */
        text-align: left;
        font-weight: 400;
        color: #3B3B3B;
        font-size: 16px;
        line-height: 150%;
        font-family: Montserrat;
        font-style: normal;
    }

    .how-it-works-image-file-large {
        width: 90%;
        padding: 0 5%;
    }

    .how-it-works-leader-container {
        width: 90%;
        overflow: auto;
        margin: 10vw 5%;
        line-height: 7.8125vw;
        font-size: 5vw;
        color: #72696A;
        text-align: left;
        font-weight: 400;
    }

    .how-it-works-box-right {
        line-height: 7.8125vw;
        font-size: 5vw;
        color: #72696A;
        text-align: left;
        margin: 0 0 3.96vw 0;
        font-weight: 400;
        width: 100%;
        margin: 2.5vw 0%;
    }

    .how-it-works-box {
        width: 90%;
        padding: 10vw 5%;
        overflow: auto;
    }

    .how-it-works-box-left {
        width: 100%;
        float: left;
        overflow: auto;
    }

    .how-it-works-box-right {
        width: 100%;
        float: left;
        overflow: auto;
        margin-top: 5vh !important;
    }

    .how-it-works-image-file {
        width: 90%;
        margin: 5%;
        float: left;
        overflow: auto;
    }

    .how-to-measure-video-section {
        width: 100vw;
        height: 57vw;
        margin: 0;
    }

    .sort-by-frame-size-button-selected {
        width: 45.8%;
        overflow: auto;
        margin: 2% 1%;
        float: left;
        line-height: 1.5;
        border: #3b3b3b 1px solid;
        background: #3b3b3b;
        color: white;
        border-radius: 30px;
        padding: 1% 0;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
    }


    .sort-by-frame-size-button {
        width: 45.8%;
        overflow: auto;
        margin: 2% 1%;
        float: left;
        line-height: 1.5;
        border: #3b3b3b 1px solid;
        color: #3b3b3b;
        border-radius: 30px;
        padding: 1% 0;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
    }

    .product-desc-dividers-header {
        font-weight: 600;
        margin-top: 25px;
    }

}
