/*For all*/

.user-policies-container {
    width: auto;
    overflow: auto;
    line-height: 1.5;
}

.user-policy-header {
    /* Header 2 */

    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 46px;
    /* or 128% */

    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    /* Primary/Soft Black */

    color: #3B3B3B;
}

.faster-shipping-area-expandable {
    margin-top: 12px;
}

.user-policy-paragraph {
    width: auto;
    overflow: auto;
    /* Body */
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */
    margin-bottom: 8px;
    color: #3B3B3B;
}

.city-name-container {
    width: 40%;
    float: left;
    overflow-y: auto;

}

.city-name-container a{
    margin-left: 10px;
    text-decoration: underline;
}

.user-policy-paragraph-narrow {
    width: 640px;
    overflow: auto;
    margin: 32px calc(50% - 320px);
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    /* or 19px */

    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;;
}

.user-policy-subheader {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 150%;
    /* identical to box height, or 36px */

    letter-spacing: 0.1em;
    text-transform: uppercase;

    /* Primary/Soft Black */

    color: #3B3B3B;
    margin: 32px 0 8px 0;
}


/*Mobile Only*/

@media screen and (min-width: 0px) and (max-width: 769px) {
    .user-policies-container {
        width: 90%;
        overflow: auto;
        margin: 150px 5%;
    }
}

/*Mobile, Tablet, Web Half Screen*/


@media screen and (min-width: 769px) and (max-width: 1100px) {
    .user-policies-container {
        width: 90%;
        overflow: auto;
        margin: 150px 5%;
    }
}

/*Normal Laptop*/

@media screen and (min-width: 1100px){
    .user-policies-container {
        width: 1120px;
        overflow: auto;
        margin: 200px calc(50% - 560px);
    }
}

