@media screen and (min-width: 0px) and (max-width: 769px) {

    .precut-size-header {
        font-size: 16px;
        font-weight: 700;
        line-height: 150%;
        text-transform: uppercase;
    }

    .select-window-multiple-window-dimensions div select, .select-mat-product-order-quantity div select{
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        width: 100%;
    }

    .select-window-multiple-window-dimensions div, .select-mat-product-order-quantity div {
        width: 100%;
    }

    /*.select-window-multiple-window-dimensions div {*/
    /*    margin-top: 5px;*/
    /*}*/

    .mobile-and-tablet-display-only {
        display: inherit;
    }

    .artwork-margin-indicator-number-right {
        display: inline;
        width: 40px;
        height: 18px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        align-items: center;
        color: #3B3B3B;
        position: absolute;
        top: -21px;
        left: 30px;
        text-align: left;
    }

    .select-mat-container {
        width: 100%;
        overflow: auto;
        overflow-x: hidden;
        /*Black Friday Code Below*/
        margin-top: 32px;
    }

    .switch-to-drawing-tool-container {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        margin: 32px 0;
        text-transform: uppercase;
        text-align: center;
        cursor: pointer;
    }



    .checkout-subtotal-figure {
        width: 100%;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 150%;
        /* identical to box height, or 36px */
        text-align: center;
        /* Primary/Soft Black */
        color: #3B3B3B;
        margin-top: 8px;
    }

    #order-checkout-button {
        width: auto;
        margin-left: calc( 50% - 73px);
        cursor: pointer;
    }

    .mobile-select-mat-checkout-button {
        width: 100%;
        overflow: auto;
        margin-top: 16px;
        padding-bottom: 32px;
        border-bottom: 1px solid #3b3b3b;
    }

    .backing-mat-instructions {
        width: 100%;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
    }

    .double-mat-price-indicator-mobile {
        float: right;
        color: #3b3b3b;
    }

    #select-matboard-window-caption-text-double-matting {
        margin-top: 12px;
        width: 100%;
    }

    .select-mat-guidance-desc-long{
        width: calc( 100% );
        float: left;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    #backing-mat-price-and-colour-container {
        width: calc(100% - 42px);
        float: left;
    }

    #window-size-round-window-container {
        width: calc(100% - 42px);
        float: left;
        overflow-x: hidden;
    }

    #double-mat-mobile-float-right  {
        width: calc(100% - 42px);
        float: left;
    }

    .diploma-mat {
        margin-top: 12px;
    }

    #select-mat-row-mobile-margin-left-1, #select-mat-row-mobile-margin-left-2, #double-mat-float-right-2, #window-size-multiple-window-option {
        width: calc(100% - 12px);
        margin-left: 12px;
    }

    #window-size-multiple-window-text {
        width: calc( 100% - 42px);
        float: left;
        overflow-x: hidden;
    }

    #backing-mat-prices-mobile {
        width: auto;
        float: left;
    }

    #select-matboard-window-sizing-mobile {
        margin-bottom: 8px;
        margin-top: 12px;
    }



    #select-mat-row-round-option {
        width: calc(100% - 12px);
        margin-left: 12px;
        margin-top: 16px;
    }

    #matboard-window-size-mobile {
        float: right;
    }

    .select-mat-request-samples {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-decoration
        : underline;
        color: #3B3B3B;
        margin: 16px 0 0 0px;
        cursor: pointer;
    }

    .matboard-colour-name-desc {
        width: auto;
        overflow: auto;
        /*margin-top: 12px;*/
        color: #3b3b3b;
        float: left;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
    }

    .select-mat-size-option-toggle {
        border-top: 1px solid #828282;
        width: 100%;
        overflow: auto;
    }

    /*.mobile-add-row-bottom {*/
    /*    border-bottom: 1px solid #666161;*/
    /*}*/

    .select-window-multiple-window-dimensions {
        width: 42%;
        overflow: auto;
        float: left;
    }

    .single-diploma-mat-box {
        width: 45%;
        overflow: auto;
        float: left;
    }

    .select-mat-row-divider {
        width: 100%;
        overflow: auto;
        float: right;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 12px;
    }

    .select-mat-guidance-button {
        width: 50px;
        overflow: auto;
        float: left;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-decoration-line: underline;
        color: #3B3B3B;
        margin-bottom: 20px;
        float: left;
        margin-left: 37px
    }

    .select-mat-input-field-direction, .select-mat-input-field-direction-long {
        width: 100%;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        display: block;
        align-items: center;
        letter-spacing: 0.02em;
        color: #666161;
    }

    .select-mat-guidance-desc {
        width: calc( 100% - 36px );
        float: left;
        overflow: auto;
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 24px */


        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .select-mat-guidance-icon {
        width: 24px;
        height: 24px;
        float: left;
        overflow: auto;
        margin: 12px 12px 12px 0;
    }

    .select-mat-sub-row {
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    #select-matboard-unit-mobile {
        width: 200px;
        overflow: auto;
        float: right;
        text-align: right;
        margin-top: 5px;
    }

    .select-mat-container-row {
        width: 100%;
        overflow: auto;
        text-align: center;
        /*float: right;*/
        /*text-align: right;*/
    }

    .select-window-multiple-window-dimensions input {
        padding: 9px 0 !important;
    }

    .req-mat-sample-button-cover {
        width: 100%;
        overflow: auto;
        margin: 0;
    }

    .cart-slider-shopped-item-subheading {
        margin-top: 15px;
    }




    .live-help-contact-box {
        position: fixed;
        width: 85vw;
        overflow: auto;
        background: white;
        right: 5vw;
        bottom: 15vw;
        padding: 2vw;
        line-height: 10vw;
        color: #72696A;
        font-weight: 400;
        border: #F59276;
        border: solid 0.5vw #F59276;
        border-radius: 1vw;
        z-index: 10;
    }

    .select-mat-section-frame-buttons {
        float: right;
    }

    .live-help-contact-box a {
        text-decoration: underline;
        color: #72696A;
    }

    .live-help-contact-box-close {
        margin-top: 1vw;
        text-align: center;
        cursor: pointer;
    }

    .live-help-contact-box-close:hover {
        text-decoration: underline;
    }

    .live-help-button {
        width: 7.5vw;
        height: 7.5vw;
        position: fixed;
        right: 3vw;
        bottom: 3vw;
        cursor: pointer;
    }


    .select-mat-section-frame-buttons {
        width: 100%;
        overflow: auto;
        margin-top: 12px;
    }

    .color-modal-open-icon {
        width: 7vw;
        margin-left: calc(50% - 3.5vw);
    }

    .artwork-margin-indicator-number-up {
        width: 40px;
        height: 18px;
        position: absolute;
        bottom: 30%;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        align-items: center;
        left: 5px;
        text-align: left;
        color: #3B3B3B;
    }

    .artwork-margin-indicator-number-down {
        width: 40px;
        height: 18px;
        text-align: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        align-items: center;
        color: #3B3B3B;
        position: absolute;
        top: 10px;
        left: 5px;
        text-align: left;
    }

    .artwork-margin-indicator-number-left {
        text-align: left;
        width: 40px;
        height: 18px;
        position: absolute;
        top: -20px;
        right: 30px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        align-items: center;
        text-align: right;
        color: #3B3B3B;
    }


    .req-mat-sample-button {
        font-size: 4vw;
        line-height: 6.5625vw;
        padding: 0 2vw;
        margin: 4vw 5vw;
        text-align: center;
    }

    .select-mat-window-quantity-header, .multiple-window-artwork-size-selection-panel {
        font-size: 5vw;
        line-height: 6.5625vw;
    }

    .multiple-window-opening-modal {
        width: 100%;
        overflow: auto;
        padding-top: 9px
    }

    .select-mat-rendering {
        width: 82%;
        /*position: fixed;*/
        z-index: 50;
        padding-bottom: 32px;
        /*margin-top: 65px;*/
        overflow-x: hidden;
        padding-left: 10%;
        padding-right: 10%;
        background: white;
    }

    .select-mat-rendering-event {
        width: 82%;
        z-index: 50;
        padding-bottom: 32px;
        overflow-x: hidden;
        padding-left: 10%;
        padding-right: 10%;
        background: white;
    }

    .select-matboard-description-container {
        width: 91%;
        overflow: auto;
        margin: 16px 4.3%;
    }

    .change-frame-orientation-instructions {
        float: left;
        margin-right: 0.5rem;
    }

    .select-mat-window-quantity-row div label, .select-mat-window-quantity-column div label {
        background: white;
    }

    .artwork-dimension-view {
        height: 14vmin;
    }

    .iconSize {
        width: 6vw;
        height: 6vw;
        float: left;
    }

    .artwork-margin-controller-up {
        width: 100%;
        height: 25%;
        text-align: center;
    }

    .artwork-margin-controller-left {
        float: left;
        position: absolute;
        width: 50%;
        left: 0;
    }

    .artwork-margin-controller-right {
        width: 50%;
        float: right;
        position: absolute;
        right: 0;
    }

    .select-mat-window-quantity-row-column-transition {
        width: 14%;
        overflow: auto;
        float: left;
        padding: 37px 0 0 0;
        text-align: center;
        overflow: hidden;
        color: #3B3B3B;
    }

    .artwork-margin-controller-down {
        width: 100%;
        height: 25%;
        /*margin: 0 calc( (100% - 10vw) / 2);*/
        text-align: center;
        position: absolute;
        bottom: 0;
    }

    .artwork-margin-indicator-up {
        width: 50%;
        font-size: 4.08vw;
        border-left: 0.3vw solid;
        margin-left: 50%;
        bottom: 100%;
        position: absolute;
    }

    .artwork-margin-indicator-down {
        font-size: 4vmin;
        border-left: 0.3vw solid;
        left: 0.15vw;
        position: absolute;
        top: 100%;
        left: 50%;
    }

    .artwork-margin-indicator-left {
        border-top: 0.3vw solid;
        position: absolute;
        right: 100%;
        top: calc(50% - 1.65vw);
    }

    .artwork-margin-indicator-right {
        float: left;
        width: 10vw;
        text-align: center;
        position: absolute;
        left: 100%;
        border-top: 0.3vw solid;
        top: calc(50% - 1.65vw);
    }

    .artwork-margin-move-up {
        margin-left: calc( 50% - 3vw);
        margin-right: calc( 50% - 3vw);
    }

    .artwork-margin-move-right {
        float: right;
        top: -1.5vw
    }

    .artwork-margin-move-down {
        margin: 0 calc(50% - 3vw);
        bottom: 1vw;
    }

    .select-mat-navigation-icon {
        width: 18px;
        overflow: auto;
        float: left;
        margin-right: 11px;
        margin-top: 3px;
    }

    .artwork-margin-move-left {
        float: left;
        top: -1.5vw;
    }

    .select-matboard-modal-subtitle {
        font-size: 4vmin;
    }

    .window-opening-field div {
        margin: 0 !important;
    }

    .window-opening-field-form-aid {
        font-size: 4vmin;
    }

    .window-opening-field-exit-container {
        padding: 0 10vmin 5vmin 10vmin;
    }

    .window-opening-field-exit {
        width: calc(100% - 0.4vmin);
        padding: 2vw 0;
    }

    .window-opening-illustration-image-file {
        width: 80%;
        margin: 0 10%;
        margin-bottom: 1vmin;
    }


    .matboard-window-action-icon-container img {
        cursor: pointer;
        width: 6vw;
    }

    .matboard-window-size-indicator {
        font-size: 4vw;
        display: none;
    }

    .select-mat-window-quantity-container .display label span {
        font-size: 4vw;
        line-height: 6.5625vw;
    }

    .window-size-input, .window-size-input label, .window-size-input input {
        font-size: 5vw;
    }

    .product-rendering-subcontrol-button {
        font-size: 1.11vw;
        line-height: 1.74vw;
        /*color: #72696A;*/
        font-weight: 400;
        border: solid 0.1vw rgba(0, 0, 0, 0.23);
        float: left;
        text-align: center;
        width: 97%;
        margin: 0 1%;
        padding: 1vw 0;
        border-radius: 0.25vw;
        cursor: pointer;
    }

    .product-rendering-subcontrol-button:hover {
        background: rgba(0, 0, 0, 0.23);
        text-align: center;
    }

    .product-rendering-subcontrol-button-image {
        width: 7.5vw;
        float: left;
        cursor: pointer;
    }

    .product-rendering-subcontrol-button-image-text {
        float: left;
        width: 45vw;
        overflow: auto;
        text-align: left;
        margin-top: 0.5vw;
        margin-left: 2.5vw;
    }

    .product-rendering-subcontrol-button-image-text:hover {
        text-decoration: underline;
        cursor: pointer;
    }


    .matboard-colour {
        width: 100%;
        height: 100%;
    }

    .matboard-selected-colour {
        width: 32px;
        height: 32px;
        border: 0.5px solid #666161;
        border-radius: 2px;
        cursor: pointer;
        margin-right: 0;
        margin-left: 12px;
        float: left;
    }

    .select-matboard-window-caption-text {
        width: 230px;
        overflow: auto;
        float: left;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-transform: uppercase;
        color: #3b3b3b;
        overflow-x: hidden;
        margin-top: 12px;
    }

    #select-matboard-window-captiaon-selected-colour-text-mobile {
        width: 100%;
        overflow: auto;
        float: left;
        margin-top: 8px;
        margin-bottom: 12px;
    }

    #double-mat-mobile-float-right-price-2 {
        width: auto;
        float: left;
        text-align: left;
    }

    #double-mat-mobile-float-right-price {
        width: auto;
        float: left;
        text-align: left;
        margin-top: 12px;
        color: #3b3b3b;
    }

    .select-matboard-window-caption-selected-colour-text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: right;
        color: #3B3B3B;
        text-align: right;
        width: auto;
        float: right;
        margin-top: 12px;
    }

    .matboard-selector-checkmark-container {
        position: absolute;
        margin: -8vw 0 0 1vw;
    }

    .matboard-selected-colour-container {
        width: 7.5vw;
        height: 7.5vw;
        float: left;
        margin: 2% 1%;
        float: left;
        border: 0.1vw solid #e9e9e9;
        border-radius: 0.5vw;
        cursor: pointer;
    }

    .change-matboard-colour-layout {
        width: 100%;
        overflow: auto;
        margin-left: 12px;
    }

    .frame-size-desc-dimensions {
        width: 100%;
        overflow-y: auto;
        float: left;
        /* margin-top: 0.25vw; */
        margin: 2.5vw 0;
    }

    .frame-size-desc-button {
        width: 100%;
        overflow-y: auto;
        float: left;
        /* margin-top: 0.25vw; */
        margin: 2.5vw 20vw;
    }

    .multiple-window-artwork-size-selection-panel {
        width: 100%;
        overflow: auto;
        border-top: solid 1px #828282;
    }

    .matboard-only-frame-size-selector {
        width: 100%;
        overflow: auto;
    }

    .matboard-only-frame-size-selection-modal {
        position: fixed;
        width: 100vw;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 30;
        background: rgba(0,0,0,0.5);
    }

    .select-your-frame-size-modal {
        width: 95%;
        background: #FEF4F1;
        overflow: auto;
        margin: 2.5%;
        border-radius: 1vmin;
    }

    .select-frame-size-leader {
        font-size: 1.11vw;
        line-height: 1.74vw;
        width: 100%;
        text-align: center;
        margin: 0;
        font-size: 4vw;
        line-height: 6.5625vw;
    }

    .show-how-to-measure-a-frame {
        width: 100%;
        text-align: center;
        float: left;
        color: #72696A;
        cursor: pointer;
        margin: 1vw 0;
        font-size: 4vw;
        line-height: 6.5625vw;
    }

    .change-matboard-colour-button {
        font-size: 4vw;
        line-height: 6.5625vw;
        width: 100%;
        display: inline-block;
        overflow: auto;
        text-align: center;
        padding-top: 0.5vw;
        color: #72696A;
        cursor: pointer;
    }

    .show-secondary-mat-colour-container {
        width: 100%;
        overflow: auto;
        margin: 10px 0;
    }

    #choose-double-matting-option {
        margin-bottom: 28px;
        margin-left: 12px;
        width: calc(100% - 12px);
    }

    #backing-mat-pricing-mobile {
        float: right;
        color: #3b3b3b;
    }

    #backing-mat-price-and-colour-container {
        width: calc(100% - 42px);
        float: left;
    }

    #backing-mat-price-and-colour-container-2 {
        width: 100%;
        overflow: auto;
        float: left;
        margin-top: 12px;
    }

    .add-to-bag-button {
        width: 150px;
        padding: 8px 16px;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        display: block;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        order: 0;
        flex-grow: 0;
        margin: 18px calc( 50% - 91px);
        background: #3B3B3B;
        border-radius: 30px;
        cursor: pointer;
    }

    .precut-size-header {
        font-size: 16px;
        font-weight: 700;
    }

    .select-matboard-window-caption-arrow {
        width: 18px;
        height: 18px;
        float: left;
        cursor: pointer;
        padding: 4px;
        margin-right: 10px;
        margin-top: 10px !important;
        margin-bottom: 15px;
    }

    .select-mat-row {
        width: 100%;
        overflow: auto;
        float: left;
    }

    .select-matboard-subdivider {
        width: 100%;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
    }

}