/*Normal Laptop*/

.landing-page-review-transition-ending-v3 {
    overflow-x: hidden
}



@media screen and (min-width: 1100px) {

    .customizable-subhead {
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        color: #3B3B3B;
        padding: 24px 405.5px 64px 405.5px;
    }

    .customizable-image-container {
        width: 993px;
        margin: 0 calc(50% - 496.5px)
    }

    .instagram-scroller-right {
        width: calc(90% - 573px);
        overflow: auto;
        float: right;
        margin-top: 300px;
        margin-right: 5%;
        margin-left: 5%;
    }


    .review-transition-widget {
        width: 1216px;
        overflow: hidden;
        margin: 83px calc( 50% - 608px) 55px;
    }

    .review-transition-logo-container {
        width: 124px;
        margin: 28px calc( 50% - 62px );
        height: 45px;
    }

    .testimonial-desc {
        width: 455px;
        height: auto;
        padding: 36px;
        float: left;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 36px */
        color: #3B3B3B;
    }

    .review-transition-widget-dials {
        display: flex;
        align-items: center;
        gap: 16px;
        width: 250px;
        overflow: auto;
        padding: 0 30px;
        float: left;
    }

    .testimonial-more-review-button:hover {
        background: #3B3B3B;
        color: white;
        text-decoration: none;
    }

    .testimonial-more-review-button {
        display: flex;
        padding: 16px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 30px;
        border: 1px solid #3B3B3B;
        width: 320px;
        overflow: auto;
        margin: 0 calc( 50% - 160px);
        text-transform: capitalize;
        text-decoration: none;
    }

    .dial-selected {
        background: #3B3B3B;
    }

    .dial {
        width: 10px;
        height: 10px;
        border-radius: 6px;
        border: 1px #3B3B3B solid;
        float: left;
    }

    .landing-page-bottom-text-highlight {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px; /* 150% */
        letter-spacing: 2.4px;
        text-transform: capitalize;
    }



    .testimonial-photo-container {
        width: 461px;
        overflow: auto;
        float: left;
    }

    .review-transition-widget-dials {
        display: flex;
        align-items: center;
        gap: 16px;
        width: 250px;
        overflow: auto;
        margin: 55px calc( 50% - 125px);
        padding: 0 34px;
    }



    .arrow-button {
        width: 50px;
        height: 50px;
        float: left;
        margin: 225px 32px;
    }

    .arrow-button:hover {
        cursor: pointer;
    }



    .testimonial-photo {
        width: 461px;
        height: 495px;
        object-fit: cover;
        float: left;
    }

    .mat-transition-container {
        width: 100%;
        height: 550px;
        overflow: hidden;
    }


    .review-transition-center {
        width: 988px;
        height: 485px;
        float: left;
        border-radius: 50px;
        box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
        overflow: hidden;
        background: #FFF;
    }


    .howItWorks-transition-img-ctr {
        width: 48%;
        height: 550px;
        object-fit: cover;
        margin-left: 1%;
        margin-right: 1%;
        float: left;
    }

    .mat-transition-img-left {
        width: 28%;
        height: auto;
        object-fit: cover;
        padding: 1%;
        float: left;
    }

    .mat-transition-img-right {
        width: 69%;
        height: auto;
        object-fit: cover;
        padding: 1% 1% 1% 0;
        float: left;
    }

    .customizable-container {
        width: 100%;
        overflow: auto;
        background: #FAF2ED;
        padding: 128px 0px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: flex;
        text-align: center;
    }

    .customizable-image-left {
        width: 297px;
        margin-right: 128px;
        margin-bottom: 5px;
    }

    .customizable-image-ctr {
        width: 312.8px;
        margin-right: 128px;
    }

    .customizable-image-right {
        width: 126px;
        margin-bottom: 10px;
    }



    .instagram-scroller-right-subtitle {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 133.333% */
        letter-spacing: 2.4px;
        color: white;
        margin-top: 24px;
    }

    .instagram-scroller-right-title {
        text-align: left;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px; /* 111.111% */
        letter-spacing: 3.6px;
        text-transform: uppercase;
        color: white;
    }

    .transition-ig-feed-container {
        margin-right: 13px;
    }

    .inspiration-post-col-photo-landing-page {
        width: 477px;
        height: 595px;
        object-fit: fill;
    }

    .transition-ig-feed-top-img {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }

    .transition-ig-feed-bottom {
        width: 496px;
        height: 352px;
        overflow: hidden;
    }

    .transition-ig-feed-bottom-img {
        width: 496px;
        height: 352px;
        object-fit: cover;
    }
    
    .transition-ig-feed-top {
        width: 100%;
        overflow: auto;
        margin-bottom: 13px;
    }

    .transition-ig-feed-container {
        width: 496px;
        height: 100%;
        overflow-y: hidden;
        float: left;
    }

    .transition-ig-feed-top-left {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        overflow: hidden;
        float: left;
    }

    .transition-ig-feed-top-right {
        width: 80%;
        overflow: auto;
        float: left;
        text-align: left;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        margin-left: 19px;
    }

    .landing-page-bottom-cover {
        width: 1440px;
        margin-left: calc(50% - 720px);
        overflow: auto;
        padding: 128px 0;
        background: #FAF2ED;
    }

    .landing-page-bottom-start-button {
        width: 194px;
        overflow: auto;
        margin: 0 calc( 50% - 97px);
    }



    .landing-page-bottom {
        width: 100%;
        color: #3B3B3B;
        text-align: center;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 46px; /* 127.778% */
        letter-spacing: 3.6px;
        text-transform: uppercase;
    }

    .testimonial-subscript {
        width: 100px;
        margin-left: 36.38px;
        float: left;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 19.5px */
        letter-spacing: 1.3px;
        text-transform: uppercase;
    }


    .howItWorks-transition-img-right {
        width: 24%;
        height: 550px;
        object-fit: cover;
        margin-right: 1%;
        float: left;
    }


    .howItWorks-transition-img-left {
        width: 24%;
        height: 550px;
        object-fit: cover;
        margin-left: 1%;
        float: left;
    }

    .landing-page-bottom-text {
        font-size: 20px;
        font-style: normal;
        line-height: 36px; /* 150% */
        letter-spacing: 2.4px;
        text-align: center;
        margin-bottom: 48px;
    }

    .landing-page-bottom-text b {
        text-transform: capitalize;
    }

    .landing-page-bottom-stars {
        width: 180px;
        overflow: auto;
        margin: 19px calc( 50% - 90px) 38.55px calc( 50% - 90px);
    }

    .transition-ig-feed-text {
        text-align: center;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 27px */
        letter-spacing: 1.8px;
        text-decoration-line: underline;
        text-transform: capitalize;
        cursor: pointer;
    }



    .transition-ig-feed {
        width: 2090px;
        height: 415px;
        margin: 0 calc(50% - 1045px) 108px calc(50% - 1045px);
    }

    .notice-bar-version2-event, .notice-bar-version2-fr-event {
        /* Notice Bar */
        position: static;
        width: 100%;
        overflow-y: auto;
        left: 0.83px;
        top: 0px;

        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */

        /*Black Friday Code Below*/

        background: black;

        /* Inside Auto Layout */

    }

    .notice-bar-version2, .notice-bar-version2-fr {
        /* Notice Bar */
        position: static;
        width: 100%;
        overflow-y: auto;
        left: 0.83px;
        top: 0px;

        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        background: #3F5C6B;
        padding-top: 4px;
      }

    .landing-page-faq {
        width: 50%;
        float: left;
        line-height: 1.5;
        margin-bottom: 64px;
    }

    .landing-page-subtext-first-v2-lead {
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 4.8px;
        text-transform: uppercase;
        color: #FFF;
    }

    .landing-page-subtext-first-v2-sublead {
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 17px 0;
        text-transform: none;
        color: #FFF;
    }

    .landing-page-standard-right-v2 {
        width: 496px;
        overflow: auto;
        float: right;
        margin-bottom: 106px;
    }


    .landing-page-header-v2 {
        width: 100%;
        height: 602px;
        overflow: hidden;
        position: initial;
        margin-top: 150px;
    }

    .landing-page-product-list {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 30px;
    }

    .landing-page-subtext-first-v2 h1 {
        font-size: 48px;
        line-height: 59px;
        letter-spacing: 0.1em;
        text-transform: uppercase
    }

    .landing-page-subtext-first-v2 {
        position: absolute;
        width: 540px;
        overflow-y: auto;
        left: 100px;
        top: 250px;
        overflow-x: hidden;

        /* Header 1 */
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        /*text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);*/
    }

    .landing-page-subtext-second-v2 {
        position: absolute;
        width: auto;
        height: 59px;
        left: 695px;
        top: 382px;

        /* Header 1 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 59px;
        /* identical to box height */

        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Secondary/White */

        color: #FFFFFF;

        text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    }

    .landing-page-image-overlay-black-v2 {
        position: absolute;
        width: 55%;
        height: 640px;

        /* Primary/Soft Black */

        background: #3B3B3B;
        opacity: 0.36;
    }

    .landing-page-image-overlay-image-v2 {
        width: 100%;
        height: 602px;
        overflow: hidden;
        left: 0px;
        object-fit: cover;
    }

    .landing-page-photo-overlay {
        position: absolute;
        width: 100%;
        max-width: 1440px;
        height: 602px;
        opacity: 0.7;
        background: linear-gradient(90deg, #432215 0%, #be8a6a00 52.79%);
    }



    .landing-page-image-overlay-grey-v2 {
        /*position: absolute;*/
        width: 100%;
        height: 602px;
        left: 0px;
        top: 0px;
        background: #C4C4C4;
    }

    .landing-page-start-button-v2 {
        display: block;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 24px;
        width: 300px;
        height: auto;
        background: #FFFFFF;
        border: 1px solid #3B3B3B;
        box-sizing: border-box;
        border-radius: 30px;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        float: left;
    }

    .landing-page-start-button-v2:hover {
        text-decoration: none;
        color: #FFFFFF;
        background: #3B3B3B;

    }

    .what-is-mat-page-v2 {
        width: 1122px;
        overflow: auto;
        overflow-x: hidden;
        margin: 250px calc(50% - 561px);
    }

    .landing-page-standard-left-v2 {
        width: 496px;
        overflow-y: auto;
        float: left;
    }

    .what-is-mat-header-v2 {
        /* Header 2 */
        width: 447px;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 46px; /* 127.778% */
        letter-spacing: 3.6px;
        text-transform: uppercase;
        margin-top: 0;
    }

    .product-benefits {
        padding: 25px 0;
    }


    .what-is-mat-desc-v2 {
        width: 406px;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
    }

    .what-is-mat-line-break-v2 {
        margin-bottom: 24px;
    }

    .what-is-mat-page-right-v2 {

    }

    .what-is-mat-image-v2 {
        width: 496px;
        overflow-y: auto;
    }

    .benefits-page-container {
        width: 100%;
        overflow: auto;
    }

    .benefits-summary-container {
        width: 1264px;
        overflow: auto;
        margin: 91.33px calc(50% - 632px);
    }

    .benefits-summary-component {
        width: 274px;
        float: left;
        padding: 0 21px
    }
    
    .benefits-page-img-container {
        width: 100%;
        overflow: auto;
    }

    .benefits-summary-component-img {
        width: 73px;
        height: 73px;
        margin-left: calc(50% - 36.5px);
        object-fit: cover;
    }

    .benefits-summary-component-lead {
        color: #3B3B3B;
        text-align: center;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 141.176% */
        letter-spacing: 1.7px;
        margin: 16px 0;
        text-transform: capitalize;
    }

    .benefits-summary-component-text {
        color: #3B3B3B;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 133.333% */
        letter-spacing: 1.5px;
    }



    .benefits-page-container-img-side {
        width: 30%;
        height: 564px;
        overflow: hidden;
        float: left;
    }

    .benefits-page-container-img-ctr {
        width: 40%;
        height: 564px;
        overflow: hidden;
        float: left;
    }

    .instagram-scroller {
        width: 477px;
        height: 600px;
        overflow-y: scroll;
        margin: 64px 32px 64px 64px;
        padding-bottom: 200px;
        float: left;
    }

    .instagram-controller-container {
        width: 1440px;
        height: 770px;
        overflow-y: hidden;
        margin: 0 calc(50% - 720px)
    }

    .what-is-mat-added-margin {
        float: right;
        margin: 120px 6.1% 100px 0;
    }


    .instagram-scroller-header-v2 {
        float: left;
        width: 444px;
        margin-top: 303px;
    }

    .how-it-works-steps-container-v2 {
        width: 100%;
        overflow: auto;
        overflow-x: hidden;
    }

    .how-it-works-header-left {
        width: 496px;
        font-size: 36px;
        float: left;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        line-height: 44px;
        text-align: left;
        float: left;
    }

    .how-it-works-subheader-left {
        width: 496px;
        overflow: auto;
        font-size: 16px;
        line-height: 250%;
        text-align: left;
        color: #3B3B3B;
        margin: 24px 0px 24px 0px;
        float: left;
    }

    #explainer-start-designing-button {

    }

    .landing-page-button-standard-v2:hover {
        background: white;
        color: #3B3B3B;
        border: #3B3B3B solid 1px;
        text-align: center;
    }





    .landing-page-button-standard-v2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: static;
        width: 193px;
        height: 52px;
        background: #3B3B3B;
        border-radius: 30px;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        flex: none;
        order: 2;
        flex-grow: 0;
        color: white;
        cursor: pointer;
        text-align: center;
    }

    .how-it-works-page-leader {
        width: 600px;
        margin-left: calc( 50% - 300px );
        margin-top: 85px;
        margin-bottom: 57px;
        text-align: center;
    }

    .how-it-works-page-button {

    }

    .how-it-works-step2-desc-v2 {
        position: static;
        width: 496px;
        overflow-x: hidden;
        overflow-y: auto;
        flex: none;
        order: 0;
        flex-grow: 0;
        /* margin: 128px calc(27.3vw - 271px) 160px calc((27.3vw - 271px) * 1.33); */
        float: right;
    }

    .how-it-works-header-right {
        position: static;
        width: 479px;
        left: 17px;
        top: 0px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 44px;
        text-align: left;
        float: left;
    }

    .how-it-works-subheader-right {
        width: 377px;
        margin-top: 128px;
        overflow: auto;
        position: static;
        /* width: 310px; */
        height: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: left;
        color: #3B3B3B;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 24px 0px;
        float: left;
    }

    .landing-page-transition-v2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* padding: 128px 160px; */
        position: static;
        width: 93%;
        overflow: auto;
        background: #3F5C6B;
        padding: 128px 3.5% 128px 3.5%;

    }

    .landing-page-transition-text-v2 {
        position: static;
        width: 1120px;
        height: 72px;
        left: 111px;
        top: 127px;

        /* Header 4 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        /* or 36px */

        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;

        /* Secondary/White */

        color: #FFFFFF;


        /* Inside Auto Layout */

        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 10px 0px;
    }

    .landing-page-review-container-v2 {
        width: 760px;
        padding: 128px calc(50% - 380px);
        overflow: auto;
        min-height: 286px;
        background: white;
    }

    .landing-page-left-arrow-v2 {
        width: 10px;
        height: 20px;
        float: left;
        margin-left: 19px;
        margin-right: 45px;
        margin-top: 150px;
        cursor: pointer;
    }

    .landing-page-review-v2 {
        width: 610px;
        /* height: 286px; */
        float: left;
        text-align: center;
        min-height: 286px;
        overflow: auto;
    }

    .landing-page-right-arrow-v2 {
        width: 10px;
        height: 20px;
        float: right;
        margin-right: 19px;
        margin-left: 45px;
        margin-top: 150px;
        cursor: pointer;
    }

    .customer-review-image {
        width: 50px;
        height: 50px;
        overflow: auto;
        border-radius: 100%;
        margin-bottom: 10px;
    }

    .landing-page-reviewer-name-v2 {

        width: 100%;
        height: 20px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        margin-bottom: 24px;
    }

    .landing-page-review-content-v2 {
        /* Body 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 150%;
        margin-bottom: 24px;
        /* or 36px */

        text-align: center;


        /* Primary/Soft Black */

        color: #3B3B3B;
    }

    .landing-page-total-review-count-v2 {
        margin-top: 24px;
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* identical to box height, or 19px */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
    }

    .landing-page-review-stars-v4 {
        width: 26px;
        height: 26px;
        float: left;
        margin: 2px;
    }


    .landing-page-review-stars-v3 {
        width: 150px;
        overflow: auto;
        margin-left: calc(50% - 75px);
        margin-bottom: 32px;
    }

    .landing-page-review-stars-v2 {
        width: 150px;
        overflow: auto;
        margin-left: calc(50% - 75px);
        margin-bottom: 24px;
    }

    .landing-page-review-stars {
        width: 30px;
        height: 30px;
        float: left;
    }

    .promotion-messages {
        width: 100%;
        overflow: auto;
        color: red;
        font-weight: 600;
        text-align: right;
        font-size: 16px;
    }


    .landing-page-review-transition-ending-v3{
        position: static;
        width: 1440px;
        margin-left: calc(50% - 720px);
        height: auto;
        left: 392px;
        top: 128px;

        /* Header 2 */

        font-family: Montserrat;
        font-style: normal;
        font-size: 36px;
        line-height: 46px;
        /* or 128% */

        text-align: center;
        letter-spacing: 0.1em;

        /* Primary/Soft Black */

        color: #3B3B3B;
        background: #FFFFFF;

        padding: 64px 0;
    }

    .landing-page-review-transition-ending-v2 {
        position: static;
        width: 1440px;
        margin-left: calc( 50% - 720px);
        height: auto;
        left: 392px;
        top: 128px;

        /* Header 2 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        /* or 128% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
        background: #D6D1BA;
        padding: 128px 0;
    }

    .landing-page-review-transition-ending-header-v3 {
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 46px; /* 127.778% */
        letter-spacing: 3.6px;
        text-transform: uppercase;
        color: #3B3B3B;
        margin-bottom: 64px;
    }

    .landing-page-review-transition-ending-header-v2 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        /* or 128% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
    }

    .landing-page-review-transition-ending-button-v3{
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: static;
        width: 790px;
        margin: 36px calc(50% - 395px);
        font-style: normal;
        font-size: 16px;
        line-height: 150%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: none;
    }

    .landing-page-review-transition-ending-button-v2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        padding: 16px 24px;
        position: static;
        width: 206px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        background: #FFFFFF;
        border-radius: 30px;
        margin-top: 19px;
        margin-left: calc( 50% - 127px);
    }

    .sort-by-frame-size-button-selected {
        width: 18.8%;
        overflow: auto;
        margin: 0.5%;
        float: left;
        line-height: 1.5;
        border: #3b3b3b 1px solid;
        background: #3b3b3b;
        color: white;
        border-radius: 30px;
        padding: 1% 0;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
    }


    .sort-by-frame-size-button {
        width: 18.8%;
        overflow: auto;
        margin: 0.5%;
        float: left;
        line-height: 1.5;
        border: #3b3b3b 1px solid;
        color: #3b3b3b;
        border-radius: 30px;
        padding: 1% 0;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
    }

}

/*Ultra Wide*/

@media screen and (min-width: 1920px) {

    .notice-bar-version2-event, .notice-bar-version2-fr-event {
        /* Notice Bar */

        position: static;
        width: 1440px;
        overlflow-y: auto;
        left: 0.83px;
        top: 0px;

        /*background: #3F5C6B;*/

        /*Black Friday Code Below*/

        background: black;


        /* Inside Auto Layout */

        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .notice-bar-version2, .notice-bar-version2-fr {
        /* Notice Bar */

        position: static;
        width: 1440px;
        overflow-y: auto;
        left: 0.83px;
        top: 0px;
        background: #3F5C6B;
        flex: none;
        order: 0;
        flex-grow: 0;
        padding-top: 4px;

    }

    .landing-page-container {
        width: 1440px;
        margin-left: calc( 50% - 720px);
    }


    .navbar-v2 {
        width: 1440px;
        overflow: auto;
    }

    .landing-page-image-overlay-black-v2 {
        width: 1440px;
    }

    .landing-page-subtext-first-v2, .landing-page-subtext-second-v2 {
        margin-left: calc(50% - 770px);
    }






}