
@media screen and (min-width: 1100px) {

    .design-guide-container {
        width: 1440px;
        margin: 125px auto;
    }

    .precut-container {
        font-style: normal;
        font-size: 13px;
        line-height: 150%;
        width: 100%;
        overflow: auto;
        margin-bottom: 24px;
        margin-top: 70px;
    }

    .precut-mat-container {
        width: 1440px;
        margin: 160px calc(50% - 720px);
    }

    .diploma-mat-box {
        width: 40%;
        float: left;
        margin: 4.9%;
        text-align: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: center;
        cursor: pointer;
        color: #3B3B3B;
        max-height: 470px
    }

    .fetch-precut-mats-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        width: 100%;
    }

    .precut-container-left-photo {
        width: 45%;
        margin: 2.5%;
        overflow: auto;
        float: left;
        object-fit: cover;
    }


    .precut-container-left {
        width: 50%;
        overflow: auto;
        float: left;
    }

    #diploma-mat-left {
        width: 50%;
    }

    #diploma-mat-right {
        width: 45%;
    }

    .precut-container-left-colours {
        width: 45%;
        margin: 2.5%;
        overflow: auto;
        float: left;
        object-fit: cover;
    }


    .precut-container-leader-left {
        width: 13px;
        height: 13px;
        float: left;
        cursor: pointer;
    }

    .precut-container-leader-right {
        float: left;
        font-family: Montserrat;
        font-style: normal;
        font-size: 13px;
        line-height: 150%;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 500;
    }

    .pre-cut-mat-box-img{
        height: 200px;
    }


    .pre-cut-mat-dropdown-menu-container {
        width: 80%;
        overflow: auto;
    }

    .precut-window-selector {
        width: 100%;
        overflow: auto;
    }

    .precut-window-selector div {
        width: 97%;
        float: left;
        font-family: Montserrat;
        font-style: normal;
        font-size: 13px;
        line-height: 150%;
        cursor: pointer;
        text-transform: capitalize;
        font-weight: 500;
        color: #3b3b3b;
    }

    .precut-window-selector div div {
        padding: 14px !important;
    }

    .precut-container-right {
        width: 35%;
        overflow: auto;
        float: left;
        margin-left: 5%;
    }


    .precut-size-header {
        width: 100%;
        overflow: auto;
        float: left;
        font-family: Montserrat;
        font-style: normal;
        font-weight : 700;
        font-size: 16px;
        line-height: 150%;
        text-transform: uppercase;
        color: #3b3b3b;
        padding-bottom: 16px;
    }

    .precut-container-leader-right:hover {
        text-decoration: underline;
    }

    .precut-container-leader-left {
        /* width: 13px; */
        height: 13px;
        margin: 3px 5px 3px 0;
        /* overflow: auto; */
        float: left;
    }

    .precut-container {
        font-family: Montserrat;
        font-style: normal;
        font-size: 13px;
        line-height: 150%;
        width: 100%;
        overflow: auto;
        margin-bottom: 24px;
    }


    .precut-sub-container {
        width: 80%;
        margin: 0 10%;
        overflow: auto;
        margin-bottom: 24px;
    }

    .precut-container-left-colours {
        width: 45%;
        margin: 2.5%;
        overflow: auto;
        float: left;
        object-fit: cover;
    }

    .precut-container-right {
        width: 35%;
        overflow: auto;
        float: left;
        margin-left: 5%;
    }



    .precut-mat-sorting-container {
        width: 50%;
        overflow: auto;
        margin: 24px 25%;
    }

    .pre-cut-mat-box {
        width: 20%;
        float: left;
        margin: 2.5% 2.5% 0 2.5%;
        text-align: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: center;
        cursor: pointer;
        color: #3B3B3B;
    }

    .matboard-colour-name:hover {
        text-decoration: underline;
    }

    .precut-mat-sorting-container {
        width: 50%;
        overflow: auto;
        margin: 24px 25%;
    }


    .precut-mat-sorting-button {
        width: 50%;
        float: left;
        text-align: center;
        overflow-y: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        text-align: center;
        color: #3B3B3B;
        cursor: pointer;
    }

    .precut-mat-sorting-button-selected {
        text-decoration: underline;
    }

    .precut-mat-sorting-button-unselected:hover {
        text-decoration: underline;
    }

    .design-guide-colour-box-color {
        border: 15px black solid;
        margin-bottom: 20px;
    }

    .design-guide-colour-box {
        width: 25%;
        overflow: auto;
        display: inline-block;
        text-align: center;
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* identical to box height, or 19px */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;

        min-height: 40px;
        float: left;

    }

    .design-guide-photo-container-subtitle {
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* or 19px */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-bottom: 96px;
    }


    .design-guide-photo-container-title {
        /* Header 2 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        /* identical to box height, or 128% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-top: 96px;
        margin-bottom: 24px;
    }

    .design-guide-photo-3 {
        width: 50%;
        overflow: auto;
        height: 300px;
        object-fit: fill;
        margin-right: 2.5%;
        margin-top: 2.5%;
        float: left;
    }

    .design-guide-photo-4 {
        width: 47.5%;
        overflow: auto;
        height: 300px;
        object-fit: fill;
        margin-top: 2.5%;
        float: left;
    }

    .design-guide-photo-6 {
        width: 42.5%;
        overflow: auto;
        object-fit: fill;
        margin-right: 7.5%;
        float: left;
    }


    .design-guide-step-by-step-container-right {
        width: 42.5%;
        overflow: auto;
        object-fit: fill;
        margin-left: 7.5%;
        float: right;
        text-align: left;
    }

    .design-guide-step-by-step-container-left {
        width: auto;
        overflow: auto;
        object-fit: fill;
        margin: 0 136px;
        float: left;
        text-align: right;
    }


    .design-guide-step-by-step-container-left-topic {
        /* Header 3 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 44px;
    }

    .design-guide-photo-5, .design-guide-photo-7 {
        width: 42.5%;
        overflow: auto;
        object-fit: fill;
        margin-left: 7.5%;
        float: left;
    }

    .design-guide-step-by-step-container-left {
        width: 42.5%;
        overflow: auto;
        object-fit: fill;
        margin-right: 7.5%;
        float: left;
        text-align: right;
        margin-left: 0;
    }

    .design-guide-photo-1 {
        width: 60%;
        overflow: auto;
        margin-right: 2.5%;
        height: 350px;
        object-fit: fill;
        float: left;
    }

    .design-guide-photo-2 {
        width: 37.5%;
        overflow: auto;
        height: 350px;
        object-fit: fill;
        float: left;
    }

    .design-guide-content-instruction-container {
        width: 100%;
        overflow: auto;
        padding: 56px 0 0 0 ;
    }


    .design-guide-desc {
        width: 50%;
        overflow: auto;
        float: left;
        font-style: normal;
        line-height: 150%;
    }


    .design-guide-section-button {
        width: 25%;
        float: left;
        overflow-y: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        text-align: center;
        color: #3B3B3B;
    }

    .design-guide-image {
        width: 45%;
        margin-right: 5%;
        overflow: auto;
        float: left;
    }

    .inspiration-logo-IG-POST-container {
        width: auto;
        float: right;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        margin: 16px;
        color: #3B3B3B;
    }

    .inspiration-logo-name {
        width: 80%;
        font-style: normal;
        font-size: 16px;
        line-height: 150%;
        font-weight: 700;
        color: white;
    }

    .inspiration-logo-name-subscript {
        width: 80%;
        font-style: normal;
        font-size: 16px;
        line-height: 150%;
        font-weight: 400;
        color: white;
    }

    .inspiration-logo-name-subscript a {
        color: white;
        cursor: pointer;
    }

    .inspiration-posts-col {
        width: 352px;
        float: left;
        overflow: auto;
        margin-top: 32px;
    }

    .inspiration-post-col-photo {
        width: 100%;
    }

    .inspiration-posts {
        width: 1120px;
        overflow-y: auto;
        column-count: 3;
        column-gap: 32px;
    }

    .inspiration-listing-header {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        /* identical to box height, or 128% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 24px 0px;
        cursor: pointer;
    }

    .inspiration-listing-desc {
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* identical to box height, or 19px */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 1;
    }

    .inspiration-page-container {
        width: 1120px;
        overflow-y: auto;
        overflow-x: hidden;
        margin: 125px calc(50% - 560px);
    }

    .general-static-page-container-heading {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 32px 0px;
    }

    .general-static-page-container-topic-heading {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 32px 0 0 0;
    }

    .general-static-page-container-topic-subheading {
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */


        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 8px 0px;
    }

    .general-static-page-container-text li, .general-static-page-container-text {
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */


        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 8px 0px;
    }

    .general-static-page-container-text-line-break {
        padding-bottom: 64px;
        border-bottom: solid 1px #3B3B3B;
    }


    .faq-page-heading {
        width: auto;
        overflow: auto;
        text-transform: uppercase;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        /* identical to box height, or 128% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-bottom: 118px;
    }


    .overview-section-left {
        width: 51%;
    }

    .overview-section-header-container {
        margin-top: -10vh;
        width: 40%;
    }

    .gallery-left-item-container-right {
        width: 85%;
        float: left;
    }


    .blog-article-photo-two-image {
        width: 49%;
        margin-right: 1%;
        float: left;
        height: 40vh;
        object-fit: cover;
    }

    .blog-article-photo-two {
        width: 60vw;
        overflow: auto;
        position: absolute;
        margin-left: -10vw;
    }


    .image-subscript {
        width: 100%;
        overflow: auto;
        margin-top: 35vh;
        padding-bottom: 3rem;
    }

    .blog-article-photo-two-image-portrait {
        width: 50%;
        overflow-y: auto;
        float: left;
    }
    .general-static-page-container {
        width: 78%;
        overflow: auto;
        margin: 150px 11%;
    }



}

@media screen and (min-width: 1920px) {

    .fetch-precut-mats-title {
        font-weight: 700;
        font-size: 14px;
        line-height: 150%;
        width: 100%;
    }


    .precut-container-leader-left {
        width: 13px;
        height: 13px;
        float: left;
        cursor: pointer;
    }

    .precut-container-leader-right {
        float: left;
        font-family: Montserrat;
        font-style: normal;
        font-size: 13px;
        line-height: 150%;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 500;
    }

    .precut-container {
        font-style: normal;
        font-size: 13px;
        line-height: 150%;
        width: 100%;
        overflow: auto;
        margin-bottom: 24px;
        margin-top: 70px;
    }

    .precut-container-left-colours {
        width: 45%;
        margin: 2.5%;
        overflow: auto;
        float: left;
        object-fit: cover;
    }

    .precut-container-right {
        width: 35%;
        overflow: auto;
        float: left;
        margin-left: 5%;
    }

    .precut-container-leader-left {
        width: 13px;
        height: 13px;
        float: left;
        cursor: pointer;
    }

    .precut-container-leader-right {
        float: left;
        font-family: Montserrat;
        font-style: normal;
        font-size: 13px;
        line-height: 150%;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 500;
    }

    .precut-container-leader-left {
        /* width: 13px; */
        height: 13px;
        margin: 3px 5px 3px 0;
        /* overflow: auto; */
        float: left;
    }

    .precut-container-leader-right:hover {
        text-decoration: underline;
    }


    .precut-container-left-photo {
        width: 45%;
        margin: 2.5%;
        overflow: auto;
        float: left;
        object-fit: cover;
    }

    .matboard-colour-name {
        margin-top: 24px;
    }

    .diploma-mat-box {
        width: 40%;
        float: left;
        margin: 4.9%;
        text-align: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: center;
        cursor: pointer;
        color: #3B3B3B;
    }

    .pre-cut-mat-box {
        width: 20%;
        float: left;
        margin: 2.5% 2.5% 0 2.5%;
        text-align: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: center;
        cursor: pointer;
        color: #3B3B3B;
    }

    .matboard-colour-name:hover {
        text-decoration: underline;
    }

    .precut-mat-sorting-container {
        width: 50%;
        overflow: auto;
        margin: 24px 25%;
    }

    .precut-mat-sorting-button {
        width: 50%;
        float: left;
        text-align: center;
        overflow-y: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        text-align: center;
        color: #3B3B3B;
        cursor: pointer;
    }

    .precut-mat-sorting-button-selected {
        text-decoration: underline;
    }

    .precut-mat-sorting-button-unselected:hover {
        text-decoration: underline;
    }

    .design-guide-photo-3 {
        width: 50%;
        overflow: auto;
        height: 500px;
        object-fit: fill;
        margin-right: 2.5%;
        margin-top: 2.5%;
        float: left;
    }

    .design-guide-container {
        width: 1440px;
        margin: 125px auto;
    }

    .design-guide-photo-container-subtitle {
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* or 19px */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-bottom: 96px;
    }


    .design-guide-photo-container-title {
        /* Header 2 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        /* identical to box height, or 128% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-top: 96px;
        margin-bottom: 24px;
    }

    .design-guide-photo-4 {
        width: 47.5%;
        overflow: auto;
        height: 500px;
        object-fit: fill;
        margin-top: 2.5%;
        float: left;
    }

    .design-guide-photo-6 {
        width: 42.5%;
        overflow: auto;
        object-fit: fill;
        margin-right: 7.5%;
        float: left;
    }


    .design-guide-step-by-step-container-right {
        width: 42.5%;
        overflow: auto;
        object-fit: fill;
        margin-left: 7.5%;
        float: right;
        text-align: left;
    }

    .design-guide-step-by-step-container-left {
        width: auto;
        overflow: auto;
        object-fit: fill;
        margin-left: 0;
        float: left;
        text-align: right;
    }


    .design-guide-step-by-step-container-left-topic {
        /* Header 3 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 44px;
    }


    .design-guide-photo-5, .design-guide-photo-7 {
        width: 42.5%;
        overflow: auto;
        object-fit: fill;
        margin-left: 7.5%;
        float: left;
    }

    .design-guide-step-by-step-container-left {
        width: 42.5%;
        overflow: auto;
        object-fit: fill;
        margin-right: 7.5%;
        float: left;
        text-align: right;
    }

    .design-guide-photo-1 {
        width: 60%;
        overflow: auto;
        margin-right: 2.5%;
        height: 600px;
        object-fit: fill;
        float: left;
    }

    .design-guide-photo-2 {
        width: 37.5%;
        overflow: auto;
        height: 600px;
        object-fit: fill;
        float: left;
    }

    .inspiration-logo-IG-POST-container {
        width: auto;
        float: right;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        margin: 16px;
        color: #3B3B3B;
    }


    .general-static-page-container-heading {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 32px 0px;
    }

    .general-static-page-container-topic-heading {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 32px 0 0 0;
    }

    .general-static-page-container-topic-subheading {
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */


        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 8px 0px;
    }

    .general-static-page-container-text li, .general-static-page-container-text {
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */


        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 8px 0px;
    }

    .general-static-page-container-text-line-break {
        padding-bottom: 64px;
        border-bottom: solid 1px #3B3B3B;
    }

    .faq-page-heading {
        width: auto;
        overflow: auto;
        text-transform: uppercase;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        /* identical to box height, or 128% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-bottom: 118px;
    }

    .overview-section-left {
        width: 51%;
    }

    .overview-section-header-container {
        margin-top: -10vh;
        width: 40%;
    }

    .gallery-left-item-container-right {
        width: 85%;
        float: left;
    }



    .blog-article-photo-two-image {
        width: 49%;
        margin-right: 1%;
        float: left;
        height: 40vh;
        object-fit: cover;
    }

    .blog-article-photo-two {
        width: 60vw;
        overflow: auto;
        position: absolute;
        margin-left: -10vw;
    }

    .image-subscript {
        width: 100%;
        overflow: auto;
        margin-top: 35vh;
        padding-bottom: 3rem;
    }

    .blog-article-photo-two-image-portrait {
        width: 50%;
        overflow-y: auto;
        float: left;
    }

    .general-static-page-container {
        width: 78%;
        overflow: auto;
        margin: 150px 11%;
    }
}