/*For all codes Only*/
@media screen and (min-width: 0px) {

    .bold {
        font-weight: 600;
    }

    .diploma-mat-rendering .artwork-margin-controller-up, .diploma-mat-rendering .matboard-window-central-controller, .diploma-mat-rendering .artwork-margin-controller-down{
        display: none;
    }

    .select-mat-row li{
        margin: 4px 0;
    }

    .matboard-size-popular-values-input {
        width: 100%;

    }


    .select-mat-guidance-desc-long{
        width: calc( 100% - 55px );
        float: left;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        margin-top: 12px;
    }

    .matboard-size-popular-values-input select {
        font-family: Montserrat;
        font-style: normal;
        font-size: 16px;
        line-height: 150%;
        padding: 10.5px 14px;
    }

    .value-type-selected {
        font-weight: bold;
    }

    .value-type-selector {
        margin-bottom: 12px;
        cursor: pointer;
    }

    .value-type-selector {

        float: left;
        margin-right: 5px;
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 24px */


        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .window-size-indicator {
        display: none;
    }


    .matboard-colour-selected {
        border: 4px solid #FFDE52;
        margin: -4px;
        border-radius: 2px;
    }

    .additional-notes-add-margin {
        margin-bottom: 18px;
    }

    .add-border-top {
        border-top: solid 1px #828282;
        padding-top: 8px;
    }

    .select-window-multiple-window-dimensions div div, .select-mat-product-order-quantity div div {
        border-radius: 2px;
        width: 100%;
    }

    .select-window-multiple-window-dimensions div, .select-mat-product-order-quantity div {
        padding: 0;
        margin: 0;
    }

    .req-mat-sample-button:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    .select-matboard-size-modal {
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
        z-index: 80;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;
    }



    .select-matboard-description-container {
        color: #3b3b3b;
    }

    .change-matboard-colour-button:hover {
        text-decoration: underline;
    }

    .select-frame-size-leader-color {
        color: #72696A;
    }

    .window-opening-field-exit:hover {
        color: white;
        background: #F59276;
    }

    .select-matboard-window {
        background-size: 100% 100%;
        box-shadow: inset 0 0.1vw 0.25vw rgba(10,10,10,.8);
        float: left;
        background: white;
    }

    .select-mat-rendering, .iconSize {
        float: left;
    }

    .iconSize, .select-matboard-artwork {
        position: relative;
        z-index: 10;
    }

    .select-matboard-artwork {
        width: 100%;
        height: 100%;
        /*box-shadow: inset 0 0.1vw 0.25vw rgba(10,10,10,.8);*/
    }

    .user-window-image {
        width: 100%;
        height: 100%;
        margin: 0;
        background: white;
        box-shadow: inset 0 0.1vw 0.25vw rgba(10,10,10,.8);
    }

    .artwork-margin-indicator-down {
        float: left;
        position: absolute;
    }


    .artwork-margin-indicator-up, .artwork-margin-indicator-number-up {
        color: #514945;
        border-color: #514945;
    }

    .artwork-margin-indicator-down, .artwork-margin-indicator-number-down {
        color: #F59276;
        border-color: #F59276;
    }

    .artwork-margin-indicator-left, .artwork-margin-indicator-number-left {
        color: #92BBD9;
        border-color: #92BBD9;
    }

    .artwork-margin-indicator-right, .artwork-margin-indicator-number-right {
        color: #B4876E;
        border-color: #B4876E;
    }

    .window-size-input {
        width: 100%;
    }

    .artwork-margin-indicator-number-right {
        display: initial
    }

    #order-checkout-button {
        width: 130px;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;
        background: #3B3B3B;
        border-radius: 30px;
        padding: 16px 24px;
        float: left;
        cursor: pointer;
    }

    #order-checkout-button-inner-layer {
        color: white;
        text-align: center;
    }

    .multiple-window-artwork-size-selection-panel-title {
        font-size: 4vw;
        line-height: 6.5625vw;
        font-family: "Montserrat";
        font-weight: 600;
        color: #72696A;
        text-transform: uppercase;
    }

    .select-mat-window-quantity-header, .multiple-window-artwork-size-selection-panel {
        width: 100%;
        overflow: auto;
        color: #72696A;
        font-weight: 400;
    }

    .select-mat-window-quantity-container label span  {
        font-family: "Montserrat";
        font-size: 1.11vw;
        line-height: 1.74vw;
        color: #72696A;
        font-weight: 400;
    }

    .select-mat-window-quantity div label, .select-window-multiple-window-dimensions div label {
        background: white;
    }



    .select-matboard-section {
        width: 100%;
        height: 100%;
        background-size: 100% 110% !important;
        background: white;
        box-shadow: inset 0 0.1rem 0.2rem rgba(10,10,10,.6);
        overflow: hidden;
    }

    .select-mat-background-wall {
        position: absolute;
        width: calc( 100% - 10rem);
        height: 75vh;
        overflow: hidden;
    }



    .select-mat-frame {
        z-index: 10;
        box-shadow: 0.5rem 0.2rem 1rem rgba(255,255,255,.6);
    }

    .select-mat-add-window-icon {
        width: 2vw;
        overflow-y: auto;
        padding-top: 0.25vw;
    }

    .select-mat-add-window-caption {
        width: calc( 100% - 0.25vw );
        color: #72696A;
    }



    .select-matboard-description-container {
        overflow-y: auto;
        float: left;
    }


    .select-mat-product-change {
        width: 100%;
        overflow: auto;
    }

    .select-mat-product-desc-name {
        font-size: 1.3888vw;
        text-transform: uppercase;
        font-weight: 500;
        color: #72696A;
        line-height: 1.66666vw;
        letter-spacing: 0.029166vw;
    }

    .select-mat-product-dimensions {
        width: auto;
        overflow: auto;
        font-size: 1rem;
        margin-top: 0.55555vw;
        font-size: 1.11vw;
        color: #72696A;
        line-height: 1.7361vw;
    }

    .artwork-margin-indicator-left  {
        font-weight: 500;
        position: absolute;
        float: left;
        text-align: right;
    }

    .select-mat-product-price {
        font-size: 2rem;
        line-height: 2;
        color: black;
        font-weight: 500;
    }

    .select-mat-product-description-content {
        line-height: 1.5;
    }

    .change-frame-orientation-button {
        float: left;
        margin: 0 0.25rem;
        cursor: pointer;
    }

    .select-mat-product-change-button {
        text-align: center;
        width: calc( 100% - 1vmin);
        overflow: auto;
        margin-top: 3vmin;
        padding: 1vmin 0;
        border: solid 0.1vmin;
    }

    .select-mat-product-order-quantity label {
        padding: 0.25vmin 1vmin;
        background: white;
    }

    .select-mat-product-order-quantity div {
        width: 100%;
    }

    .change-frame-orientation-section {
        width: 100%;
        overflow: auto;
    }

    .select-mat-product-order-quantity-container {
        width: auto;
        overlfow: auto;
        margin-top: 2vmin;
    }

    .select-mat-window-quantity-column {
        float: left;
        width: 20%;
        overflow: auto;
    }

    .select-mat-window-quantity {
        float: left;
        width: 35%;
        margin-right: 27px;
    }

    .select-mat-window-quantity-row {
        float: left;
        width: 20%;
        overflow: auto;
    }

    .select-mat-window-quantity-column div, .select-mat-window-quantity div, .select-mat-window-quantity-row div {
        margin: 0;
    }

    .select-mat-window-quantity-row-column-middle {
        width: 8px;
        overflow: hidden;
        float: left;
        font-style: normal;
        padding: 20px 12px 0 12px;
        color: #3b3b3b;
    }

    .window-opening-field-bridge {
        width: 20%;
        float: left;
        overflow: auto;
        margin-top: 1vmin;
        text-align: center;
    }

    .select-mat-window-quantity-container {
        width: 100%;
        overflow: auto;
    }

    .display {
        color: #3B3B3B;
    }



    .artwork-dimension-view {
        width: calc(70% - 0.4vmin);
        overflow: hidden;
        margin: 5% 10% 2% 10%;
        padding: 5%;
        background: white;
        border-radius: 1vmin;
        border: solid #F59276 0.2vmin;
    }

    .iconSize:hover {
        cursor: pointer;
    }

    .window-movement-indicator {
        width: 100%;
        font-size: 2vmin;
        margin-top: 0.5vmin;
        text-align: center;
    }

    /*.artwork-margin-controller-up, .artwork-margin-controller-down {*/
    /*    width: 30%;*/
    /*    overflow: auto;*/
    /*    text-align: center;*/
    /*    margin-left: 35%;*/
    /*}*/

    .artwork-margin-indicator-up {
        font-weight: 500;
        position: absolute;
        float: left;
    }

    .window-opening-field {
        width: 40%;
        float: left;
        overflow: hidden;
    }

    .artwork-margin-indicator-right {
        font-weight: 500;
        position: absolute;
        text-align: center;
    }

    .matboard-window-central-controller {
        width: 100%;
        position: absolute;
        top: calc( 50% - 3vw);
        height: 50%;
    }

    .select-matboard-input-modal {
        width: 90vmin;
        background: #FEF4F1;
        overflow: auto;
        margin: 5vmin calc(50% - 45vmin);
        border-radius: 1vmin;
        margin-top: 5vmin;
    }

    .select-matboard-modal-title {
        width: auto;
        overflow: auto;
        background: white;
        padding: 3vmin;
        text-align: center;
        font-weight: 500;
        border-bottom: 1vmin;
        border-color: #F59276;
        border-bottom: solid 0.1vmin #F59276;
    }

    .select-matboard-modal-subtitle {
        padding: 0 10vmin 5vmin 10vmin;
        line-height: 2;
    }

    .window-opening-field-container {
        width: 100%;
        overflow: auto;
    }


    .window-opening-field-form-aid {
        width: 100%;
        overflow: auto;
        margin-top: 2vmin;
        text-align: right;
        font-size: 2vmin;
    }

    .no-wrap {
        white-space: nowrap;
    }

    .window-opening-field-exit {
        overflow: auto;
        text-align: center;
        border-radius: 1vmin;
        border: solid #F59276 0.2vmin;
        color: #F59276;
        font-size: 2vmin;
        font-weight: 500;
        float: right;
    }

    .window-opening-field-exit-container {
        width: auto;
        overflow-y: auto;
        padding: 0 10vmin 1vmin 10vmin;
        cursor: pointer;
    }

    .window-size-indicator {
        font-size: 2vmin;
        width: calc(100% - 3vw);
        padding: 0;
        min-width: 1.5vw;
        overflow: auto;
        text-align: center;
        float: left;
        display: none;
    }

    .matboard-window-action-icon-container {
        width: 100%;
        overflow: auto;
    }

    .matboard-window-size-indicator {
        font-weight: 500;
        cursor: pointer;
    }

    .multiple-window-artwork-size-selection-panel {
        width: 100%;
        overflow: auto;
    }

    .second-mat-price {
        float: right;
    }

}