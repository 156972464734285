@media screen and (min-width: 0px) and (max-width: 769px) {

    .thumbs-wrapper {
        display: none;
    }

    .carousel-root {
        width: 100%;
    }

    .fetch-precut-mats-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        width: 130px;
        margin-left: calc(50% - 65px);
    }

    .carousel-image-item {
        height: 275px;
    }

    .thumbs-wrapper img {
        height: 50px;
    }

    .precut-window-selector .add-text-fields-text-field-title {
        font-size: 16px;
        font-weight: 700;
    }

    .precut-window-selector {
        width: 97.5%;
    }

    .precut-container .precut-container {
        width: 100%;
        overflow: auto;
        margin-bottom: 16px;
    }

    .precut-container-left-photo, .precut-container-left-colours {
        overflow: auto;
        float: left;
        display: table;
    }

    .precut-container-left {
        width: 100%;
        overflow: auto;
        overflow-x: scroll;
        display: flex;
    }

    .precut-container-right .add-text-fields-text-field-title {
        font-size: 16px !important;
        font-weight: 700;
        text-transform: uppercase;
    }

    .diploma-mat-box {
        width: 90%;
        float: left;
        margin: 4.9%;
        text-align: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: center;
        cursor: pointer;
        color: #3B3B3B;
    }

    .pre-cut-mat-box {
        width: 50%;
        float: left;
        margin: 2.5% 0;
        text-align: center;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        cursor: pointer;
        color: #3B3B3B;
    }

    .design-guide-colour-box-color {
        border: 15px black solid;
        margin-bottom: 20px;
        max-width: 180px;
    }

    .design-guide-colour-box-color-description {
        height: 75px;
    }

    .design-guide-colour-box{
        width: 50%;
        overflow: auto;
        display: inline-block;
        text-align: center;
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* identical to box height, or 19px */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        height: 375px;

        /* Primary/Soft Black */

        color: #3B3B3B;

        min-height: 40px;
        float: left;

    }



    .general-static-page-container {
        width: 90%;
        overflow: auto;
        margin: 100px 5%;
    }

    .faq-page-heading {
        width: auto;
        overflow: auto;
        text-transform: uppercase;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        /* identical to box height, or 128% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-bottom: 32px;
    }

    .design-guide-container {
        width: auto;
        margin: 52px 0px;
    }


    .precut-container-leader-left {
        width: 16px;
        height: 16px;
        float: left;
        cursor: pointer;
        padding: 3px 6px 3px 0;
    }

    .precut-container-leader-right {
        float: left;
        font-family: Montserrat;
        font-style: normal;
        font-size: 16px;
        line-height: 150%;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 500;
    }

    /*.gallery-container {*/
    /*    margin: 64px 0;*/
    /*}*/

    .design-guide-mobile-dropdown-icon {
        float: left;
    }

    .design-guide-step-by-step-container-left {
        width: auto;
        overflow: auto;
        object-fit: fill;
        margin: 0 16px;
        float: left;
        text-align: center;
    }

    .design-guide-selection-button-container {
        width: 100%;
        display: flex;
    }


    .design-guide-photo-container-subtitle {
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* or 19px */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-bottom: 96px;
        margin-left: 16px;
        margin-right: 16px;
        width: auto;
    }


    .design-guide-photo-container-title {
        /* Header 2 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        /* identical to box height, or 128% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-top: 96px;
        margin-bottom: 24px;
        margin-left: 16px;
        margin-right: 16px;
        width: auto;
    }

    .design-guide-photo-3 {
        width: 50%;
        overflow: auto;
        height: 150px;
        object-fit: fill;
        margin-right: 2.5%;
        margin-top: 2.5%;
        float: left;
    }

    .design-guide-photo-4 {
        width: 47.5%;
        overflow: auto;
        height: 150px;
        object-fit: fill;
        margin-top: 2.5%;
        float: left;
    }

    .design-guide-photo-6 {
        width: 100%;
        overflow: auto;
        object-fit: fill;
        float: left;
    }


    .design-guide-step-by-step-container-right {
        width: auto;
        overflow: auto;
        object-fit: fill;
        margin-left: 16px;
        margin-right: 16px;
        float: left;
        text-align: center;
    }



    .design-guide-step-by-step-container-left-topic {
        /* Header 3 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 44px;
    }


    .design-guide-photo-5, .design-guide-photo-7 {
        width: 100%;
        overflow: auto;
        object-fit: fill;
        float: left;
    }

    /*.design-guide-step-by-step-container-left {*/
    /*    width: 42.5%;*/
    /*    overflow: auto;*/
    /*    object-fit: fill;*/
    /*    margin-right: 7.5%;*/
    /*    float: left;*/
    /*    text-align: right;*/
    /*}*/

    .design-guide-photo-1 {
        width: 60%;
        overflow: auto;
        margin-right: 2.5%;
        height: 150px;
        object-fit: fill;
        float: left;
    }


    .design-guide-content-instruction-container {
        width: 100%;
        overflow: auto;
        padding: 56px 0 0 0 ;
    }

    .design-guide-photo-2 {
        width: 37.5%;
        overflow: auto;
        height: 150px;
        object-fit: fill;
        float: left;
    }


    .design-guide-desc {
        width: 100%;
        overflow: auto;
        float: left;
        font-style: normal;
        line-height: 150%;
    }


    .design-guide-section-button {
        float: left;
        overflow-y: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        text-align: center;
        color: #3B3B3B;
    }

    .design-guide-image {
        width: 100%;
        overflow: auto;
        float: left;
    }

    .inspiration-logo-IG-POST-container {
        width: auto;
        float: right;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        margin: 11px;
        color: #3B3B3B;
    }

    .inspiration-logo-name {
        width: 60%;
        float: left;
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        color: white;
    }

    .inspiration-posts-col,.inspiration-logo-name-subscript a {
        width: 98%;
        float: left;
        overflow: auto;
        color: white;
    }




    .instagram-scroller-right-title {
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 30px */
        font-variant: small-caps;
        letter-spacing: 2px;
        color: white;
    }

    /*.inspiration-post-col-photo {*/
    /*    width: 100%;*/
    /*}*/

    .inspiration-posts {
        width: 98%;
        overflow-y: auto;
        overflow-x: hidden;
        margin: 64px 1%;
    }

    .inspiration-listing-header {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        /* identical to box height, or 128% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 24px 0px;
        cursor: pointer;
    }

    .inspiration-listing-desc {
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 19px */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 1;
    }

    .inspiration-page-container {
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: 120px;
    }

    .general-static-page-container-heading {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 32px 0px;
    }

    .inspiration-posts {
        width: 100%;
        overflow-y: auto;
    }

    .general-static-page-container-topic-heading {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 32px 0 0 0;
    }

    .general-static-page-container-topic-subheading {
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */


        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 8px 0px;
    }

    .general-static-page-container-text li, .general-static-page-container-text {
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */


        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 8px 0px;
    }

    .general-static-page-container-text-line-break {
        padding-bottom: 64px;
        border-bottom: solid 1px #3B3B3B;
    }

    .overview-section-left {
        width: 91%;
    }
    .overview-section-right, .gallery-left-item-container-left {
        display: none;
    }
    .overview-section-header-container {
        margin-top: -15vh;
        width: 85%;
    }
    .overview-section-header, .overview-section-subheader {
        max-width: 75%;
        font-size: 0.8rem;
    }
    .gallery-left-item-container-right {
        width: 100%;
    }
    .overview-section-left {
        border-right: none;
    }

    .blog-article-photo-two-image {
        width: 100%;
        float: left;
        overlow-y: auto;
        margin-bottom: 5vh;
    }

    .blog-article-photo-two {
        width: 100%;
        overflow: auto;
    }

    .image-subscript {
        width: 100%;
        overflow: auto;
        margin-top: -10vh;
        padding-bottom: 3rem;
    }

    .blog-article-photo-two-image-portrait {
        width: 100%;
        overflow-y: auto;
    }

    .design-guide-section-button-selected {
        text-align: center;
        margin: 0 auto;
    }

    .design-guide-mobile-dropdown-icon {
        transform: rotate(
                270deg
        );
        margin-left: 5px;
        width: 10px;
    }

}