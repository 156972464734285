@media screen and (min-width: 769px) and (max-width: 1100px) {

    .select-window-multiple-window-dimensions div div, .select-mat-product-order-quantity div div {
        width: 100%;
    }

    .design-guide-colour-container {
        width: 642px;
        overflow: auto;
        padding: 40px 0;
        margin: 0 auto;
    }

    .cart-start-new-design-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 24px;
        position: static;
        /* left: 34.22%; */
        /* right: 34.22%; */
        top: 5.13%;
        bottom: 92.1%;
        background: #3B3B3B;
        border-radius: 30px;
        /* flex: none; */
        order: 1;
        flex-grow: 0;
        margin: 24px auto;
        color: white;
        width: 250px;
        text-transform: uppercase;
    }

    .MuiFormControl-marginNormal {
        margin: 0 !important;
    }

    .navbar-column-title-checkout-section {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        text-transform: uppercase;
    }

    .cart-slider-shopped-item-title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: #3B3B3B;
        margin-top: 13px;
        text-transform: uppercase;
    }

    .cart-slider-second-mat {
        width: 100%;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: #3B3B3B;
        text-transform: uppercase;
    }

    .cart-slider-shopped-item-center {
        width: calc(100% - 64px);
        overflow: auto;
        float: left;
        margin-left: 16px;
    }


    .cart-slider-container {
        width: 100%;
        z-index: 100;
        background: rgba(0,0,0,0.5);
        position: absolute;
        top: 150px;
    }

    .customer-satisfaction-policy-banner {
        font-weight: 400;
        font-size: 2.0779vw;
        line-height: 1.5;
        padding: 1vw 0.5vw;
        width: 93%;
        overflow: auto;
        text-align: left;
        margin-left: 1vw;
    }

    .cart-slider-shopped-item-container {
        width: 100%;
        overflow: auto;
        margin-bottom: 10px;
    }

    .cart-slider-second-mat-container {
        width: calc( 100% - 80px);
        overflow: auto;
        float: left;
        margin-left: 65px;
    }

    .shipping-cost-container {
        width: 100%;
        overflow-y: auto;
        padding: 8px 0;
    }


    .cart-slider-top-container {
        width: 100%;
        overflow-y: auto;
        font-weight: 400;
        color: #F59276;
        text-align: center;
    }

    .cart-slider-start-designing-button {
        width: 196px;
        overflow: auto;
        margin-left: calc( 50% - 98px);
        margin-bottom: 552px;
    }

    .show-empty-cart {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #3B3B3B;
        text-transform: uppercase;
    }

    .cart-slider-second-mat-item-details {
        width: 60%;
        float: left;
        overflow: auto;
    }

    .cart-slider-second-mat-item-price {
        width: 40%;
        overflow: auto;
        float: right;
    }

    #checkout-cart-slider {
        float: right;
        padding-right: 10px;
        margin: 15px 25px;
        width: 28px;
        height: 28px;
    }

    #checkout-arrow-slider {
        float: left;
        margin: 15px 25px;
        width: 28px;
        height: 28px;
    }

    .cart-item-count-display {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        color: #3B3B3B;
        text-transform: uppercase;
    }

    .discount-tier-info {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        margin-top: 10px;

    }

    .cart-item-count-display-empty-cart {
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* or 19px */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
    }

    .cart-slider-free-shipping-banner {
        width: 100%;
        overflow: auto;
        margin-top: 24px;
        padding-bottom: 16px;
    }

    .cart-slider-top-container-divider {
        width: 100%;
        height: 2.5px;
    }

    .cart-slider-top-container-divider-inner-gauge {
        width: 40%;
        height: 2.5px;
        background: #F59276;
    }

    .cart-slider-shopped-item-left {
        width: 48px;
        height: 48px;
        left: 0px;
        top: 0px;
        border: 1px solid #666161;
        box-sizing: border-box;
        border-radius: 2px;
        float: left;
    }

    .cart-slider-shopped-item-center-center {
        width: 70%;
        overflow: auto;
        float: left;
    }

    .cart-subtotal-strikethrough {
        text-decoration: line-through;
    }

    .cart-slider-shopped-item-right {
        width: 30%;
        float: right;
        text-align: right;
        /* margin-top: 20px; */
        margin-right: 0;
        font-size: 16px;
        line-height: 150%;
        font-weight: bold;
    }

    /*.cart-slider-shopped-item-title {*/
    /*    font-size: 2.0779vw;*/
    /*    line-height: 3.247vw;*/
    /*    font-weight: 400;*/
    /*    margin-bottom: 5px;*/
    /*}*/

    .cart-slider-shopped-item-subtitle {
        /* Body 4 (Bolded) */

        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        color: #3B3B3B;
    }

    .cart-slider-shopped-item-quantity {
        width: 50%;
        height: auto;
        margin-top: 20px;
        float: left;
    }

    .cart-slider-shopped-item-cover {
        width: 100%;
        overflow: auto;
        border-bottom: 1px solid #F2F2F4;
        padding: 24px 0;
    }

    .cart-slider-shopped-item-summary {
        width: 100%;
        padding: 20px 0;
        overflow: auto;
        bottom: 0px;
        background: white;
    }

    .item-total-coupon-section {
        font-size: 2.0779vw;
        line-height: 3.247vw;
        line-height: 18px;
        text-decoration: underline;
        font-weight: 500;
    }

    .item-total-title, .item-total-amount {
        width: 49%;
        float: left;
    }

    .item-total-amount {
        text-align: right;
        font-weight: 500;
    }

    #cart-slider-checkout-button {
        text-transform: uppercase;
        padding: 0;
        width: 400px;
        text-align: center;
        padding-top: 1.5vw;
        padding-bottom: 1.5vw;
        color: white;
        background: #F59276;
        font-weight: 400;
        border: solid 0.21vw #f59276;
        /* position: fixed; */
        font-size: 2.0779vw;
        line-height: 3.247vw;;
        /* bottom: 20px; */
        /* right: 10px; */
        /* margin: 0; */
        margin: 0;
        margin-top: 40px;
    }

    .delivery-payments-section-shipping-payments-container .cart-slider-shopped-item-cover {
        width: 100%;
        overflow: auto;
        border-bottom: 1px solid #F2F2F4;
        padding: 0.5vw 0;
        margin-bottom: 0.5vw;
    }

    .delivery-payments-section-shipping-payments-container .cart-slider-shopped-item-left {
        width: 48px;
        height: 48px;
        float: left;
        /* margin: 30px 20px 20px 20px; */
        margin: 2%;
    }

    .delivery-payments-section-shipping-payments-container .cart-slider-shopped-item-center {
        width: 55%;
        overflow: auto;
        float: left;
        /* margin: 20px 0; */
        margin-left: 1%;
    }

    /*.delivery-payments-section-shipping-payments-container .cart-slider-shopped-item-right {*/
    /*    width: 20%;*/
    /*    float: right;*/
    /*    text-align: right;*/
    /*    margin: 0;*/
    /*    font-size: 2.0779vw;*/
    /*    line-height: 3.247vw;*/
    /*}*/

    /*.delivery-payments-section-shipping-payments-container .cart-slider-shopped-item-title, .delivery-payments-section-shipping-payments-container  .cart-slider-shopped-item-subtitle {*/
    /*    font-size: 2.0779vw;*/
    /*    line-height: 3.247vw;*/
    /*}*/

    .delivery-payments-section-shipping-payments-container .cart-slider-shopped-item-center {
        margin-top: 0;
    }

    .cart-slider-content {
        width: 80%;
        padding: 0 10%;
        height: auto;
        overflow-y: auto;
        /*position: absolute;*/
        background: white;
    }

    .cart-subtotal-section {
        width: 100%;
        overflow: auto;
        border-bottom: solid #3B3B3B 1px;
        padding-bottom: 24px;
    }

    .shopping-cart-checkout-button {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        display: block;
        align-items: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        background: #3F5C6B;
        border-radius: 30px;
        width: 100px;
        padding: 8px 16px;
        text-align: center;
        float: left;
        margin-right: 10px;
    }

    .shopping-cart-subtotal-container {
        text-align: right;
        width: 300px;
        float: right;
        /* margin-top: 30px; */
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        /* margin: 0px 4px; */
    }

    .cart-subtotal-strikethrough-large {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 150%;
        /* identical to box height, or 36px */


        /* Primary/Soft Black */

        color: #3B3B3B;

        opacity: 0.25;
        text-decoration: line-through;
    }

    .cart-subtotal-highlight {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 0px 4px;
    }

    .discount-info-desc {
        /* Entry Label */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        /* or 14px */

        letter-spacing: 0.02em;

        /* Secondary/Green */

        color: #78A891;
    }

    .cart-slider-item-controller {
        width: calc(100% - 80px);
        overflow: auto;
        float: left;
        margin-left: 65px;
    }

    .cart-slider-shopped-item-quantity input {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #3B3B3B;
        padding: 8px;
        width: 100%;
        margin: 0 auto 0;
        border: none;
    }

    .cart-slider-shopped-item-quantity-button {
        width: 20px;
        overflow: auto;
        float: left;
        font-size: 26px;
        cursor: pointer;
        margin-top: 5px !important;
    }

    .custom-design-matboard-sign {
        width: 15px;
        height: 15px;
        position: absolute;
        padding: 2.5px;
        margin-top: 30px;
        margin-left: -20px;
        background: #F2F2F2;
        border: 1px solid #666161;
        border-radius: 2px;
    }

    .cart-slider-second-mat-subtitle {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        text-transform: initial;
    }

    .custom-mat-design-diagram img {
        width: 100%;
        height: 191px;
    }

    .cart-slider-shopped-item-subheading {
        margin-top: 13px;
    }

    .cart-slider-shopped-item-total {
        float: right;
        text-align: right;
        font-size: 16px;
        line-height: 150%;
        font-weight: bold;
    }

    .cart-slider-ending-main {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #3B3B3B;
    }

    .cart-slider-ending-subheading {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        text-align: center;
        letter-spacing: 0.02em;
        color: #3B3B3B;
        margin-top: 16px;
    }

    .cart-slider-ending {
        margin-top: 32px;
        width: 100%;
        overflow: auto;
        padding-bottom: 320px;
    }

    .fetch-note-details-container-item-detail {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: #3B3B3B;
    }

    .shopping-cart-checkout-button-thick {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        display: block;
        align-items: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        background: #3F5C6B;
        border-radius: 30px;
        width: 86px;
        padding: 16px 24px;
        text-align: center;
        float: left;
    }

    .shopping-cart-subtotal-container-large {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 150%;
        /* identical to box height, or 36px */


        /* Primary/Soft Black */

        color: #3B3B3B;
        width: auto;
        overflow: auto;
        float: right;
        text-align: right;
    }

    .cart-slider-shopped-item-quantity .select-window-multiple-window-dimensions {
        width: 43px;
        overflow: auto;
        float: left;
        margin: 0 8px;
        border: 1px solid #666161;
    }

    .precut-size-header {
        font-size: 16px;
        line-height: 150%;
        font-weight: 600;
        margin-bottom: 16px;
        text-transform: uppercase;
    }


}