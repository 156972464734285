@media screen and (min-width: 1100px) {

    .select-mat-input-field-direction-long a {
        color: #666161;
        text-decoration: underline;
    }

    .select-mat-input-field-direction-long a:hover {
        color: #666161;
        text-decoration: underline;
    }

    .second-mat-price {
        width: auto;
        margin-left: 7px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
    }

    #select-mat-row-mobile-margin-left-2 {
        cursor: pointer;
    }

    .mobile-and-tablet-display-only {
        display: none;
    }

    .checkout-subtotal-figure {
        width: auto;
        overflow: auto;
        float: right;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 150%;
        /* identical to box height, or 36px */


        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-top: 8px;
    }



    .MuiIconButton-root-123 {
        padding: 0.5vw 1vw !important;
    }

    .select-mat-window-quantity-row div label, .select-mat-window-quantity-column div label {
        background: white;
    }

    .window-opening-field div {
        margin: 0 !important;
    }

    .window-size-input, .window-size-input label, .window-size-input input {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #3B3B3B;
        border: solid 1px #666161;
        border-radius: 2px;
    }


    .matboard-size-selection-demo-photo {
        width: 50%;
        margin-left: 25%;
    }

    .req-mat-sample-button {
        font-size: 1.11vw;
        line-height: 1.32vw;
        padding: 0 1vw;
        float: left;
    }

    .live-help-contact-box {
        position: fixed;
        width: 30vw;
        overflow: auto;
        background: white;
        right: 2vw;
        bottom: 5vw;
        padding: 2vw;
        line-height: 2.5vw;
        color: #72696A;
        font-weight: 400;
        border: #F59276;
        border: solid 0.1vw #F59276;
        border-radius: 1vw;
        z-index: 10;
    }

    .live-help-contact-box a {
        text-decoration: underline;
        color: #72696A;
    }

    .live-help-contact-box-close {
        margin-top: 1vw;
        text-align: center;
        cursor: pointer;
    }

    .live-help-contact-box-close:hover {
        text-decoration: underline;
    }

    .live-help-button {
        width: 2vw;
        height: 2vw;
        position: fixed;
        right: 2vw;
        bottom: 2vw;
        cursor: pointer;
    }

    .select-mat-section-frame-buttons {
        width: 100%;
        overflow: auto;
        margin-top: 12px;
    }

    .color-modal-open-icon {
        width: 1.5vw;
        margin: 0 calc(50% - 0.75vw);
        cursor: pointer;
    }

    .select-mat-window-quantity-row-column-transition {
        /*width: 9px;*/
        /*overflow: auto;*/
        /*float: left;*/
        /*padding: 25px 35px 0 35px;*/
        /*text-align: center;*/
        /*overflow: hidden;*/
        /*color: #3B3B3B;*/
        width: 20%;
        overflow: auto;
        float: left;
        padding: 25px 0;
        text-align: center;
        overflow: hidden;
        color: #3B3B3B;
    }

    .window-opening-field-exit-container {
        padding: 0 10vmin 1vmin 10vmin;
    }

    .select-mat-window-quantity-header, .multiple-window-artwork-size-selection-panel {
        font-size: 1.11vw;
        line-height: 1.74vw;
        overflow: auto;
    }

    .multiple-window-opening-modal {
        width: 100%;
        overflow: auto;
        padding-top: 9px
    }



    #diploma-mat-container {
        margin-top: 150px;
    }

    .matboard-window-central-controller {
        width: 100%;
        position: absolute;
        top: 25%;
        height: 50%;
    }

    .select-matboard-description-container {
        width: 45%;
        margin-right: 5%;
        overflow: auto;
        float: right;
        padding-bottom: 36px;
    }

    .add-to-bag-button {
        width: 150px;
        overflow: auto;
        background: #3B3B3B;
        border-radius: 30px;
        color: white;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        padding: 8px 16px;
        float: left;
        cursor: pointer;
    }

    .select-mat-row-divider {
        width: calc(99% - 36px);
        overflow: auto;
        float: right;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 12px;
    }

    .select-matboard-window-caption-text {
        width: 250px;
        overflow: auto;
        float: left;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-transform: uppercase;
        color: #3b3b3b;
        margin-top: 4px;
        cursor: pointer;
    }

    .select-mat-row {
        width: 100%;
        overflow: auto;
        float: left;
    }

    .select-mat-sub-row {
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .diploma-mat {
        margin-top: 12px;
    }

    .select-matboard-window-caption-arrow {
        width: 18px;
        height: 18px;
        float: left;
        cursor: pointer;
        padding: 4px;
        margin-right: 10px;
        margin-top: 4px;
    }

    .select-mat-guidance-icon {
        width: 24px;
        height: 24px;
        float: left;
        overflow: auto;
        margin: 12px 12px 12px 0;
    }

    .select-window-multiple-window-dimensions {
        /*width: 181px;*/
        width: 40%;
        overflow: auto;
        float: left;
    }

    .single-diploma-mat-box {
        width: 45%;
        overflow: auto;
        float: left;
    }

    .single-diploma-mat-box-line-breaker {
        width: 10%;
        overflow: auto;
        float: left;
        text-align: center;
        padding-top: 40px;
    }

    .select-mat-guidance-desc {
        width: calc( 100% - 72px - 55px );
        float: left;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        margin-top: 12px;
        margin-bottom: 12px;
    }



    .select-mat-guidance-button {
        width: 50px;
        overflow: auto;
        float: right;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        margin-top: 12px;
        text-decoration-line: underline;
        color: #3B3B3B;
    }

    .select-matboard-subdivider {
        width: 100%;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
    }

    .select-mat-container-row {
        width: 200px;
        float: right;
        text-align: right;
    }

    .product-desc-dividers-header {
        font-weight: 600;
        margin-top: 25px;
    }

    .product-desc-dividers {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        width: 40%;
        float: left;
        margin: 0 5%;
    }

    .change-frame-orientation-instructions {
        float: left;
        margin-right: 0.5rem;
    }

    .window-opening-field input {
        padding-top: 2px !important;
    }

    .window-opening-field label + .MuiInput-formControl-24 {
        margin-top: 0px !important;
    }

    .artwork-dimension-view {
        height: 7vmin;
    }

    .iconSize {
        width: 1.5vw;
        height: 1.5vw;
    }

    .artwork-margin-controller-up {
        width: 1.5vw;
        height: 25%;
        margin: 0 calc( (100% - 1.5vw) / 2);
        text-align: center;
    }

    .artwork-margin-controller-left {
        width: 50%;
        height: 100%;
        float: left;
        position: absolute;
    }

    .artwork-margin-controller-right {
        width: 50%;
        height: 100%;
        float: right;
        position: absolute;
        right: 0;
    }

    .artwork-margin-move-down {
        position: absolute;
        bottom: 0;
        left: calc(50% - 0.75vw);
    }

    .artwork-margin-controller-down {
        width: 100%;
        height: 25%;
        text-align: center;
        position: absolute;
        bottom: 0;
    }

    .artwork-margin-indicator-up {
        font-size: 1.5vmin;
        float: left;
        margin-top: -1vw;
        width: 1.5vw;
        margin-left: 0.75vw;
        border-left: 0.1vw solid;
        position: absolute;
        bottom: 100%;
    }

    .artwork-margin-indicator-left {
        width: 1.5vw;
        font-size: 1.5vmin;
        padding: 0.3vw 0;
        border-top: 0.1vw solid;
        position: absolute;
        right: 100%;
        top: 50%;
    }

    .artwork-margin-indicator-right {
        float: right;
        font-size: 1.5vmin;
        padding: 0.3vw 0;
        border-top: 0.1vw solid;
        text-align: center;
        left: 100%;
        top: 50%;
    }

    .artwork-margin-move-right {
        position: absolute;
        float: right;
        margin: 0;
        top: calc(50% - 0.75vw);
        right: 0;
    }

    .artwork-margin-move-left {
        float: left;
        margin: 0;
        top: calc(50% - 0.75vw);
        position: absolute;
    }

    .artwork-margin-indicator-down {
        font-size: 1.5vmin;
        width: 1.5vw;
        border-left: 0.1vw solid;
        position: absolute;
        top: 100%;
        left: 50%;
    }

    .select-matboard-modal-subtitle {
        padding: 0 10vmin 5vmin 10vmin;
        line-height: 2;
        font-size: 2vmin;
    }

    .window-opening-field-form-aid {
        font-size: 2vmin
    }

    .window-opening-field-exit {
        width: 10vw;
        padding: 1vw;
    }

    .window-opening-illustration-image-file {
        width: 50%;
        margin-left: 25%;
        margin-bottom: 1vmin;
    }

    .matboard-window-action-icon-container img {
        cursor: pointer;
        width: 1.5vw;
    }

    .matboard-window-size-indicator {
        font-size: 0.8vw;
    }

    .artwork-margin-indicator-number-up {
        width: 30px;
        height: 18px;
        bottom: 30px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        align-items: center;
        color: #3B3B3B;
        position: absolute;
        left: 5px;
        text-align: left;
    }

    .switch-to-drawing-tool-container {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 16px 0px;
        width: 400px;
        text-align: center;
        margin-left: calc( 50% - 200px);
        text-transform: uppercase;
        cursor: pointer;
    }

    .req-mat-sample-button-cover {
        /* Primary/Light Gray */
        width: 445px;
        overflow: auto;
        margin-left: calc( 50% - 232.5px );
        background: #F2F2F2;
        border-radius: 2px;
        /* Inside Auto Layout */
        flex: none;
        order: 3;
        flex-grow: 0;
        padding: 10px;

    }

    .select-mat-navigation-icon {
        float: left;
        width: 18px;
        height: 18px;
        margin-right: 10px;
    }

    .select-mat-navigation-script {
        /* Body */
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
    }

    .artwork-margin-indicator-number-down {
        width: 40px;
        height: 18px;
        position: absolute;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        align-items: center;
        color: #3B3B3B;
        top: 30%;
        left: 5px;
        text-align: left;
    }

    .artwork-margin-indicator-number-top {
        text-align: center;
        width: 30px;
        height: 18px;
        background: white;
    }

    .artwork-margin-indicator-number-left {
        position: absolute;
        width: 40px;
        height: 18px;
        top: -20px;
        right: 30px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        align-items: center;
        text-align: right;
        color: #3B3B3B;
    }

    .artwork-margin-indicator-number-right {
        width: 40px;
        height: 18px;
        position: absolute;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        align-items: center;
        color: #3B3B3B;
        top: -21px;
        left: 30px;
        text-align: left;
    }

    .select-mat-input-field-direction, .select-mat-input-field-direction-long {
        width: 100%;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        display: flex;
        align-items: center;
        letter-spacing: 0.02em;
        color: #666161;
    }

    .select-mat-input-field-direction-long {
        width: 100%;
        overflow: auto;
        display: block;
    }

    .multiple-window-artwork-size-selection-panel-title {
        font-size: 1.11vw;
        line-height: 1.74vw;
        font-weight: 600;
        color: #72696A;
        text-transform: uppercase;
    }

    .product-rendering-subcontrol-button {
        font-size: 1.11vw;
        line-height: 3.247vw;
        font-weight: 400;
        border: solid 0.1vw rgba(0, 0, 0, 0.23);
        float: left;
        text-align: center;
        width: 45.5%;
        padding: 0.25vw 0;
        border-radius: 0.25vw;
        cursor: pointer;
    }

    #product-rendering-subcontrol-button-left {
        margin: 0 3% 0 0;
    }

    #product-rendering-subcontrol-button-right {
        margin: 0 0 0 3%;
    }

    .product-rendering-subcontrol-button:hover {
        background: rgba(0, 0, 0, 0.23);
        text-align: center;
    }

    .product-rendering-subcontrol-button-image {
        width: 1.5vw;
        float: right;
        margin: 0.25vw 0.5vw;
        cursor: pointer;
    }

    .product-rendering-subcontrol-button-image-text {
        float: right;
        padding: 0.25vw 0;
    }

    .product-rendering-subcontrol-button-image-text:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    .matboard-colour {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .select-matboard-description-container .matboard-selected-colour {
        float: right;
    }

    .matboard-selected-colour {
        width: 32px;
        height: 32px;
        border: 1px solid #666161;
        border-radius: 2px;
        cursor: pointer;
        margin-right: 0;
        margin-left: 12px;
    }

    .select-matboard-window-caption-selected-colour-text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: right;
        color: #3B3B3B;
        text-align: right;
        width: auto;
        float: right;
        margin-top: 4px;
    }

    .matboard-colour-name-desc {
        width: auto;
        overflow: auto;
        float: left;
        margin-top: 5px;
        color: #3b3b3b;
    }

    .matboard-selector-checkmark-container {
        position: absolute;
        margin: -2.5vw 0.5vw;
    }

    .matboard-selected-colour-container {
        width: 30px;
        height: 30px;
        float: left;
        margin: 6px;
        border-radius: 1px;
        cursor: pointer;
        border: 0.5px solid #666161;
    }

    .change-matboard-colour-layout {
        width: 100%;
        overflow: auto;
        margin: 6px 0;
        min-height: 36px;
    }



    .frame-size-desc-dimensions {
        width: auto;
        overflow-y: auto;
        float: left;
        margin-top: 0.25vw;
    }

    .frame-size-desc-button {
        width: auto;
        overflow-y: auto;
        float: left;
        margin-left: 0.5vw;
        text-decoration: underline;
    }

    .multiple-window-artwork-size-selection-panel {
        width: 100%;
        overflow: auto;
        border-top: solid 1px #828282;
    }

    .select-mat-request-samples {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-decoration-line: underline;
        color: #3B3B3B;
        margin: 10px 0 0 0;
        cursor: pointer;
    }

    .matboard-only-frame-size-selector {
        width: 100%;
        overflow: auto;
        padding: 0;
    }

    .matboard-only-frame-size-selection-modal {
        position: fixed;
        width: 100vw;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 30;
        background: rgba(0,0,0,0.5);
    }

    .select-your-frame-size-modal {
        width: 90vw;
        background: #FEF4F1;
        overflow: auto;
        border-radius: 1vmin;
        margin: 1vw 4vw;
    }

    .select-frame-size-leader {
        font-size: 1.11vw;
        line-height: 1.74vw;
        width: 100%;
        text-align: left;
        color: #72696A;
    }

    .show-how-to-measure-a-frame {
        width: 100%;
        text-align: left;
        font-size: 1.11vw;
        line-height: 1.74vw;
        float: left;
        color: #72696A;
        cursor: pointer;
        margin-top: 0.75vw;
    }

    .change-matboard-colour-button {
        font-size: 1.11vw;
        line-height: 1.74vw;
        width: 100%;
        overflow: auto;
        text-align: center;
        color: #72696A;
        cursor: pointer;
    }

    .select-mat-size-option-toggle {
        border-top: 1px solid #828282;
        width: calc(100% - 36px);
        overflow: auto;
        margin-left: 36px;
    }

    #choose-double-matting-option {
        margin-bottom: 12px;
    }

    .backing-mat-instructions {
        width: 100%;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
    }

    .show-secondary-mat-colour-container {
        width: 100%;
        overflow: auto;
        margin: 10px 0;
    }

    .select-mat-container-narrow {
        min-height: 95vh;
        margin-top: 170px;
    }

    .matboard-size-frations .window-size-input {
        width: 44%;
        float: left;
        margin-right: 5%;

    }

    .matboard-size-frations select {
        float: right;
        padding: 10.5px 14px;
        margin: 0;
        background: white;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */

        display: flex;
        align-items: center;
        text-align: center;
    }

    .matboard-size-fractional-input {
        width: 65%;
        float: left;
    }

    .matboard-size-frations div {
        border-radius: 2px;
    }





}