.product-offering-container {
    width: 100%;
    overflow: auto;
    background: #f9f9f9;
}

.product-offering-item {
    width: 23%;
    padding: 5vh 4%;
    float: left;
    background: #EBEDEE;
    margin: 1%;
    margin-bottom: 5vh;
}

.blog-article-content th {
    border: 0.5px solid #72696A;
    padding: 10px 2.5px;
    width: 40%;
}

.product-offering-image-container {
    width: 100%;
    overflow: auto;
}

.product-offering-image {
    width: 100%;
    overflow: auto;
}

.product-offering-title {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    font-weight: 500;
    font-size: 1rem;
}

.product-offering-desc {
    line-height: 2;
    text-align: center;
    margin-bottom: 0.5rem;
    color: #858585;
}

.product-offering-price-range {
    text-align: center;
    color: #858585;
    line-height: 2;
    font-size: 0.8rem;
}

.product-offering-action-button {
    font-size: 0.8rem;
    text-align: center;
    width: 45%;
    float: left;
    text-transform: uppercase;
    margin-top: 2vh;
    padding: 2%;
    cursor: pointer;
    font-weight: 500;
}

.product-offering-action-button:hover {
    text-decoration: underline;
}


.product-listing-action-button:hover {
    color: white;
    background: #F59276;
    /*padding: 1.01vw 0;*/
    /*border: #F59276 0.208vw solid;*/
}

.select-product-margin {
    margin: 1.6666vw 0;
}

.product-list-item-image {
    width: 100%;
}

/*.customer-review-container {*/
/*    width: 100%;*/
/*    overflow: auto;*/
/*}*/

.product-list-item a {
    text-decoration: none;
}

.product-listing-desc-select-option {
    color: #72696A;
}

.product-listing-desc-select-option a {
    color: #72696A;
}

.product-listing-desc-select-option:hover {
    cursor: pointer;
    text-decoration: underline;
}

.pricing-table table {
    width: 100%;
    margin-bottom: 2.5vw;
}





/*Mobile Only*/

@media screen and (min-width: 0px) and (max-width: 769px) {


    .product-list-container {
        width: calc(100% - 10vw);
        overflow: auto;
        margin: 0 5vw 5.45vw 5vw;
    }

    table {
        border-collapse: collapse;
        color: #72696A;
    }

    .product-divider {
        width: 9.6875vw;
        height: 2.5vw;
        background-color: #F59276;
        margin: 12.5vw calc( (100% - 9.6875vw ) /2 ) 4.375vw;
    }

    .product-listing-header {
        text-align: center;
        color: #72696A;
        font-size: 6.25vw;
        width: 100%;
        line-height: 7.8125vw;
        font-weight: 600;
    }

    .product-listing-desc {
        margin: 0;
        text-align: left;
        color: #72696A;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5;
    }

    .product-listing-price {
        font-size: 5vw;
        text-align: center;
        margin: 7.5vw;
        font-weight: 600;
        color: #F59276;
        line-height: 6.5625vw;
    }

    .product-listing-action-button {
        width: 60.3125vw;
        border: 0.9375vw solid #F59276;
        text-align: center;
        margin: 0 calc( (100% - 60.3125vw) /2 );
        padding: 4.53125vw 0;
        color: #F59276;
        text-transform: uppercase;
    }

    .product-listing-action-button-bottom-margin {
        margin-bottom: 4.5315vw;
    }

    .product-list-item {
        margin-bottom: 11.71875vw;
    }

    #product-list-item-box-2 img {
        margin: 0;
    }

    .landing-page-how-it-works-transition {
        width: auto;
        background-color: #F59276;
        padding: 12.5vw 4.6875vw;
    }

    .shipping-info-desc {
        font-size: 5vw;
        line-height: 7.8125vw;
        text-align: center;
        color: white;
        margin-bottom: 7.5vw;
    }

    .shipping-info-highlights {
        font-weight: 500;
        font-size: 5vw;
        color: white;
        line-height: 7.8125vw;
        text-align: center;
    }

    .pricing-table {
        width: 100%;
        margin-bottom: 5vw
    }

    .product-listing-header, .product-listing-desc {
        margin: 5vh 0;
    }

    .fair-pricing-discount-section-container {
        width: 90%;
        margin: 5%;
    }

    .product-listing-subdescription {
        text-align: center;
        color: #72696A;
        font-weight: 400;
        font-size: 5vw;
        line-height: 7.8125vw;
    }

}

/*Mobile, Tablet, Web Half Screen*/

@media screen and (min-width: 769px) and (max-width: 1100px) {

    .product-listing-header {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 32px 0px 24px 0;
    }

    .product-listing-subdescription {
        line-height: 3.247vw;
        color: #72696A;
        font-weight: 400;
        font-size: 2.0779vw;
    }

    .fair-pricing-discount-section-container {
        width: 90%;
        margin: 200px 5%
    }

    .pricing-table .product-listing-desc {
        width: 80%;
        font-size: 2.0779vw;
        margin: 4vmin 10% 5vw 10%;
    }

    .product-offering-header {
        font-size: 4.156vw;
    }

    .faq-table-row {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 24px */
    }

    table {
        border-collapse: collapse;
        font-size: 2.597vw;
        color: #72696A;
    }

    .pricing-table {
        width: 95%;
        margin: 5vw 2.5%;
    }

    .product-list-item {
        width: 61.688vw;
        overflow: auto;
        float: left;
        margin: 0 calc( (100% - 61.688vw) / 2);
    }

    .product-list-item-single {
        width: auto;
        overflow: auto;
    }

    #product-list-item-box-2 img {
        margin: 0;
    }


    .product-divider {
        width: 5.195vw;
        height: 1.039vw;
        background-color: #F59276;
        margin: 5.195vw calc( (100% - 5.195vw) / 2) 1.818vw;
    }

    .product-listing-header {
        width: auto;
        font-size: 2.597vw;
        text-align: center;
        letter-spacing: 0.03vw;
        color: #72696A;
        font-weight: 600;
        margin: 0 0 3.117vw 0;
        line-height: 3.117vw;
    }

    .product-listing-desc {
        font-size: 1.11vw;
        line-height: 3.247vw;
        color: #72696A;
        text-align: center;
        margin: 0 0 5.195vw 0;
        font-weight: 400;
        font-size: 2.0779vw;
    }

    .product-listing-price {
        color: #F59276;
        text-align: center;
        font-weight: 600;
        font-size: 2.0779vw;
        line-height: 3.247vw;
        margin: 0 0 2.403vw 0;
    }

    .product-listing-action-button {
        background-color: inherit;
        font-size: 1.11vw;
        color: #F59276;
        padding: 1.01vw 0;
        text-align: center;
        width: 10.9vw;
        margin-left: calc((100% - 20.3896vw) /2);
        border: #F59276 0.208vw solid;
        cursor: pointer;
        width: 20.3896vw;
        padding: 1.883vw 0;
        font-size: 2.0779vw;
        line-height: 3.247vw;
        margin-bottom: 5vw;
    }

    .product-listing-action-button-bottom-margin {
        margin-bottom: 3.96vw;
    }

    .product-offering-header {
        text-align: center;
        letter-spacing: 0.67px;
        line-height: 7.4026vw;
        margin: 5.55vh 0;
        color: #72696A;
        font-size: 4.1558vw;
        text-transform: uppercase;
    }

    .product-list-container {
        width: calc(100% - 18vw);
        overflow: auto;
        margin: 0 9vw 5.45vw 9vw;
    }


}

.product-listing-desc li {
    text-align: left;
}

/*Normal Laptop*/

@media screen and (min-width: 1100px) {

    .product-listing-subdescription {
        width: 50%;
        margin: 0 25%;
        font-size: 1.11vw;
        line-height: 1.74vw;
        padding: 0 0 5vw 0;
        color: #72696A;
    }

    table {
        border-collapse: collapse;
        color: #72696A;
    }

    .table {
        font-weight: 400;
    }

    .pricing-table {
        width: 78%;
        margin: 24px 11%;
        font-size: 12px;
    }

    .product-list-item {
        width: 25vw;
        overflow: auto;
        float: left;
    }

    .product-list-item-single {
        width: auto;
        overflow: auto;
        margin: 0 36.5vw;
    }

    .product-list-item-image {
        width: 22.5vw;
        margin: 0 1.25vw;
    }

    .product-divider {
        width: 2.78vw;
        height: 0.56vw;
        background-color: #F59276;
        margin: 2.78vw calc(50% - 1.39vw) 0.97vw;
    }


    .product-listing-header {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 200px 0px 24px 0;
    }

    .product-listing-desc {
        position: static;
        width: 100%;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */

        text-align: center;

        /* Primary/Soft Black */

        color: #3B3B3B;

        text-align: center;

        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        margin-top: 24px;
        margin-bottom: 49px;
    }

    .product-listing-price {
        color: #F59276;
        text-align: center;
        font-weight: 600;
        font-size: 1.11vw;
        line-height: 1.46vw;
        margin: 0 0 1.28vw 0;
    }

    .product-listing-action-button {
        background-color: inherit;
        font-size: 1.11vw;
        color: #F59276;
        padding: 1.01vw 0;
        text-align: center;
        width: 10.9vw;
        margin-left: calc(50% - 5.45vw);
        border: #F59276 0.208vw solid;
        cursor: pointer;
    }

    .product-listing-action-button-bottom-margin {
        margin-bottom: 1.96vw;
    }


    #product-list-item-box-1, #product-list-item-box-2 {
        margin-left: 2vw;
    }

    /*.product-list-item-image {*/
    /*    margin: 0;*/
    /*}*/

    .product-offering-header {
        text-align: center;
        margin: 5.55vh 0;
        font-weight: 600;
        text-transform: uppercase;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 150%;
        text-align: left;

    }

    .product-list-container {
        width: calc(100% - 18vw);
        overflow: auto;
        margin: 0 9vw 5.45vw 9vw;
    }

    .pricing-table .product-listing-desc {
        font-size: 1.11vw;
        line-height: 1.74vw;
        margin-top: 4vmin;

    }




}