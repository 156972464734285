/*Normal Laptop*/

@media screen and (min-width: 769px) and (max-width: 1100px) {

    .carousel-root {
        width: 100%;
    }

    .pre-cut-mat-box-img {
        width: 100%;
        max-height: 230px;
    }

    .pre-cut-mat-box-img {
        height: 300px;
    }

    .pre-cut-mat-box {
        width: 50%;
        float: left;
        margin-bottom: 10%;
    }

    .fetch-precut-mats-title-container {
        width: 100%;
        text-align: center;
        margin-top: 12px;
        line-height: 1.5;
    }

    .carousel-image-item {
        height: 450px;
    }

    .thumbs-wrapper img {
        height: 75px;
    }

    .precut-container-left-photo {
        width: 100%;
        overflow: auto;
        float: left;
        object-fit: cover;
    }


    .precut-container-leader-left {
        width: 13px;
        height: 13px;
        float: left;
        cursor: pointer;
    }

    .precut-container-leader-right {
        float: left;
        font-family: Montserrat;
        font-style: normal;
        font-size: 13px;
        line-height: 150%;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 500;
    }

    .design-guide-colour-box-color {
        border: 15px black solid;
        margin-bottom: 20px;
    }

    .design-guide-colour-box {
        width: 33%;
        overflow: auto;
        display: inline-block;
        text-align: center;
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* identical to box height, or 19px */

        height: 375px;

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;

        min-height: 40px;
        float: left;

    }

    .design-guide-photo-container-subtitle {
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* or 19px */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-bottom: 96px;
    }


    .design-guide-photo-container-title {
        /* Header 2 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        /* identical to box height, or 128% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-top: 96px;
        margin-bottom: 24px;
    }

    .design-guide-photo-3 {
        width: 50%;
        overflow: auto;
        height: 300px;
        object-fit: fill;
        margin-right: 2.5%;
        margin-top: 2.5%;
        float: left;
    }

    .design-guide-photo-4 {
        width: 47.5%;
        overflow: auto;
        height: 300px;
        object-fit: fill;
        margin-top: 2.5%;
        float: left;
    }

    .design-guide-photo-6 {
        width: calc( 100% - 272px);
        overflow: auto;
        object-fit: fill;
        margin: 36px 136px;
        float: right;
    }


    .design-guide-step-by-step-container-right {
        width: auto;
        overflow: auto;
        object-fit: fill;
        margin: 0 136px;
        float: left;
        text-align: left;
    }

    .design-guide-step-by-step-container-left {
        width: auto;
        overflow: auto;
        object-fit: fill;
        margin: 0 136px;
        float: left;
        text-align: left;
    }


    .design-guide-step-by-step-container-left-topic {
        /* Header 3 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 44px;
    }


    .design-guide-photo-5, .design-guide-photo-7 {
        width: calc( 100% - 272px);
        overflow: auto;
        object-fit: fill;
        margin: 36px 136px;
        float: left;
    }



    .design-guide-photo-1 {
        width: 60%;
        overflow: auto;
        margin-right: 2.5%;
        height: 300px;
        object-fit: fill;
        float: left;
    }

    .design-guide-photo-2 {
        width: 37.5%;
        overflow: auto;
        height: 300px;
        object-fit: fill;
        float: left;
    }

    .design-guide-content-instruction-container {
        width: auto;
        overflow: auto;
        padding: 56px 49px 0 49px;
    }


    .design-guide-desc {
        width: 50%;
        overflow: auto;
        float: left;
        font-style: normal;
        line-height: 150%;
    }


    .design-guide-section-button {
        width: 25%;
        float: left;
        overflow-y: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        text-align: center;
        color: #3B3B3B;
    }

    .design-guide-image {
        width: 45%;
        margin-right: 5%;
        overflow: auto;
        float: left;
    }

    .design-guide-container {
        width: auto;
        margin: 170px 0px;
    }

    .inspiration-logo-IG-POST-container {
        width: auto;
        float: right;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        margin: 11px;
        color: #3B3B3B;
    }

    .inspiration-logo-name {
        width: 100%;
        font-style: normal;
        font-size: 27.589px;
        font-weight: 700;
        line-height: 150%;
        color: white;
    }

    .inspiration-logo-name-subscript a {
        font-size: 27.589px;
        font-style: normal;
        font-weight: 400;
        color: white;
        line-height: 150%;
    }



    .inspiration-posts-col {
        width: 304px;
        float: left;
        overflow: auto;
        margin-top: 32px;
    }

    .inspiration-post-col-photo {
        /*width: 100%;*/
        /*height: 187px;*/
        /*margin-top: -5px;*/
        /*object-fit: cover;*/
    }

    #inspiration-post-col-photo-main-page {
        width: 100%;
    }

    .inspiration-posts {
        width: 640px;
        overflow-y: auto;
        overflow-x: hidden;
        margin: 64px calc(50% - 320px);
        column-count: 2;
        column-gap: 32px;

    }

    .inspiration-listing-header {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        /* identical to box height, or 128% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 24px 0px;
        cursor: pointer;
    }

    .inspiration-listing-desc {
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* identical to box height, or 19px */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 1;
    }

    .inspiration-page-container {
        width: 768px;
        overflow-y: auto;
        overflow-x: hidden;
        margin: 150px calc(50% - 384px);
    }



    .general-static-page-container-heading {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 32px 0px;
    }

    .general-static-page-container-topic-heading {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 32px 0 0 0;
    }

    .general-static-page-container-topic-subheading {
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */


        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 8px 0px;
    }

    .general-static-page-container-text li, .general-static-page-container-text {


        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */


        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 8px 0px;
    }

    .general-static-page-container-text-line-break {
        padding-bottom: 64px;
        border-bottom: solid 1px #3B3B3B;
    }

    .faq-page-heading {
        width: auto;
        overflow: auto;
        text-transform: uppercase;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        /* identical to box height, or 128% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-bottom: 118px;
    }


    .faq-page-heading {
        width: auto;
        overflow: auto;
        text-transform: uppercase;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        /* identical to box height, or 128% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-bottom: 118px;
    }

    .overview-section-left {
        width: 51%;
    }

    .overview-section-header-container {
        margin-top: -20vh;
        width: 40%;
    }

    .gallery-left-item-container-right {
        width: 85%;
        float: left;
    }

    .blog-article-photo-two-image {
        width: 49%;
        margin-right: 1%;
        float: left;
        height: 40vh;
        object-fit: cover;
    }

    .blog-article-photo-two {
        width: 60vw;
        overflow: auto;
        position: absolute;
        margin-left: -10vw;
    }

    .fetch-precut-mats-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        width: 100%;
    }

    .image-subscript {
        width: 100%;
        overflow: auto;
        margin-top: 35vh;
        padding-bottom: 3rem;
    }

    .blog-article-photo-two-image-portrait {
        width: 50%;
        overflow-y: auto;
        float: left;
    }



    .general-static-page-container-heading {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 32px 0px;
    }

    .general-static-page-container-topic-heading {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 32px 0 0 0;
    }

    .general-static-page-container-topic-subheading {
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */


        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 8px 0px;
    }

    .general-static-page-container-text li, .general-static-page-container-text {
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */


        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 8px 0px;
    }

    .general-static-page-container-text-line-break {
        padding-bottom: 64px;
        border-bottom: solid 1px #3B3B3B;
    }

    .faster-shipping-area-chevron {
        width: 15px;
        height: 18px;
        float: left;
        margin: 0 15px 0 10px;
    }

    .faster-shipping-expanable-container {
        width: auto;
        overflow: auto;
        padding: 12px 0 11px 0;
    }

    .faster-shipping-area-chevron-inverse {
        width: 15px;
        height: 18px;
        float: left;
        margin: 0 15px 0 10px;
        transform: rotate(180deg);
    }

    .faster-shipping-area-province-name-divider {
        width: 100%;
        height: 1px;
        background: #3B3B3B;
        opacity: 0.25;
    }

    .faster-shipping-area-province-name {
        width: calc(100% - 60px);
        float: left;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
    }

    .faster-shipping-expanable-subcontainer {
        width: 100%;
        overflow: auto;
        padding-bottom: 11px;
    }

    .general-static-page-container {
        width: 78%;
        overflow: auto;
        margin: 150px 11%;
    }


    .precut-container-left {
        width: 100%;
        overflow: auto;
        float: left;
    }

    .precut-container-left-colours {
        width: 45%;
        margin: 2.5%;
        overflow: auto;
        float: left;
        object-fit: cover;
    }



}