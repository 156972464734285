.shipping-summary-status-header {
    width: 100%;
    overflow-y: auto;
    /*background: #8BBCA9;*/
    background: #9eb9d3;
    color: white;
    padding: 1rem 0;
    margin: 0.5rem 0;
    font-weight: 500;
    border-radius: 0.3rem;
    text-align: center;
}

.delivery-info-header-regular-2 {
    /* Header 5 */

    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    /* identical to box height, or 19px */

    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: #000000;
    margin: 16px 0;
}

.change-order-actions-container {
    width: 100%;
    overflow-y: auto;
    margin-top: 1rem;
}

.change-order-action-button {
    padding: 1rem 0;
    margin: 0.5rem 1% 0.5rem 0;
    width: 46%;
    overflow-y: auto;
    float: left;
    /* border-radius: 0.3rem; */
    cursor: pointer;
    transition: background linear 0.3s;
    border: solid 0.1rem #514945;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 500;
    color: #514945;
}

.modify-order-action-button {
    padding: 1rem 0;
    margin: 0.5rem 0 0.5rem 1%;
    width: 48%;
    overflow-y: auto;
    float: left;
    /* border-radius: 0.3rem; */
    cursor: pointer;
    transition: background linear 0.3s;
    border: solid 0.1rem #514945;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 500;
    color: #514945;
}

.order-confirmation-header-subtext {
    width: 100%;
    overflow: auto;
    margin-top: 0.5rem;
    font-size: 0.8rem;
}

.order-confirmation-header-subtext img {
    width: 1.5vw;
}

.change-order-action-button:hover {
    background: #514945;
    color: white;
}

.modify-order-action-button:hover {
    background: #514945;
    color: white;
}

.order-tracking-day-breaker {
    text-align: center;
    font-size: 1rem;
    padding-bottom: 2rem;
    font-weight: 600;
    padding-top: 2rem;
}

.order-tracking-order-container {
    width: auto;
    overflow: auto;
    border-top: 0.05rem solid;
    margin-top: 2rem;
}

.tracking-order-input-container div {
    width: 100% !important;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.tracking-order-input-container {
    margin-bottom: 1rem;
}

.order-tracking-error-section div {
    text-align: center;
    padding: 5rem 0;
    color: #f44336;
}

.checkout-shop-more-button {
    width: 100px;
    height: 20px;
    background: #3B3B3B;
    font-size: 13px !important;
    border-radius: 30px;
    padding: 16px 24px;
    margin-left: calc( 50% - 74px);
}

.order-confirmation-header {
    width: 100%;
    overflow-y: auto;
    border-bottom: 1px solid #b8b8b8;
    font-weight: 600;
    text-align: center;


}

.delivery-info-header-emphasis {
    font-family: Montserrat;
    width: 100%;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 150%;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 11px;
}

.delivery-info-header-regular {
    /* Header 5 */

    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    /* identical to box height, or 19px */

    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: #000000;
}

.order-confirmation-highlight {
    font-weight: 500;
    margin-right: 0.5rem;
}

.customer-info-row-container {
    width: auto;
    overflow: auto;
    padding: 1rem;
    border: solid rgb(223, 223, 223) 0.075rem;
    border-bottom: none;
    line-height: 2;
}

.customer-info-row-container-left {
    width: 35%;
    float: left;
    color: #555;
    font-weight: 500;
    font-size: 0.8rem;
}

.customer-info-row-container-right {
    width: 65%;
    overflow-y: auto;
    float: left;
    color: #72696A;
    font-size: 0.8rem;
    font-weight: 500;
}

.confirmation-customer-info {
    margin-top: 3.5%;
}

#customer-info-bottom-row {
    border-bottom: solid rgb(223, 223, 223) 0.075rem;
}

/*For all*/




/*Mobile Only*/

@media screen and (min-width: 0px) and (max-width: 769px) {

    .future-inspirations-button {
        justify-content: center;
        align-items: center;
        padding: 16px 0px;
        width: 269px;
        background: #3F5C6B;
        border-radius: 30px;
        text-align: center;
        color: white;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px auto;
        margin-top: 24px;
    }

    .delivery-info-wrapper-subheading {
        /* Entry Label */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        /* or 14px */

        text-align: center;
        letter-spacing: 0.02em;

        /* Primary/Soft Black */

        color: #3B3B3B;
        text-align: center;

    }
}

/*Mobile, Tablet, Web Half Screen*/


@media screen and (min-width: 0px) and (max-width: 769px) {
    .order-tracking-entry-form {
        margin: 0%;
        overflow: hidden;
    }

    #colour-description-container {
        width: 100%;
        text-align: center;
        display: block;
        margin-bottom: 8px;
    }
}

/*Normal Laptop*/

@media screen and (min-width: 769px) and (max-width: 1450px) {

    .future-inspirations-button {
        justify-content: center;
        align-items: center;
        padding: 16px 24px;
        width: 269px;
        background: #3F5C6B;
        border-radius: 30px;
        text-align: center;
        color: white;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px auto;
        margin-top: 24px;
    }

    .order-tracking-entry-form {
        margin: 0 25%;
    }
}

/*External Monitor*/

@media screen and (min-width: 1450px) and (max-width: 1920px) {

    .future-inspirations-button {
        justify-content: center;
        align-items: center;
        padding: 16px 24px;
        width: 269px;
        background: #3F5C6B;
        border-radius: 30px;
        text-align: center;
        color: white;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px auto;
        margin-top: 24px;
    }

    .order-tracking-entry-form {
        margin: 0 50%;
    }
}

/*4K, UHD Monitors*/

@media screen and (min-width: 1920px) {
    .order-tracking-entry-form {
        margin: 0 50%;
    }


    .future-inspirations-button {
        justify-content: center;
        align-items: center;
        padding: 16px 24px;
        width: 269px;
        background: #3F5C6B;
        border-radius: 30px;
        text-align: center;
        color: white;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px auto;
        margin-top: 24px;
    }

}



@media screen and (min-width: 0px) and (max-width: 769px) {

    .delivery-info-wrapper-subheading {
        /* Entry Label */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        /* or 14px */

        text-align: center;
        letter-spacing: 0.02em;

        /* Primary/Soft Black */

        color: #3B3B3B;
        text-align: center;

    }


    .delivery-info-wrapper {
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */

        text-align: center;

        color: #000000;


        /* Inside Auto Layout */

        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 16px 0px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1100px) {

    .delivery-info-wrapper-subheading {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        text-align: center;
        letter-spacing: 0.02em;
        color: #3B3B3B;
        text-align: center;
        width: 450px;
        margin: 0 auto;
    }

    .delivery-info-wrapper {
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */

        text-align: center;

        color: #000000;


        /* Inside Auto Layout */

        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 16px 0px;
    }
}

@media screen and (min-width: 1100px) {

    .delivery-info-wrapper-subheading {

        /* Entry Label */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        /* or 14px */

        text-align: center;
        letter-spacing: 0.02em;

        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 11;
        flex-grow: 0;
        margin: 24px 0px;
    }

    .delivery-info-wrapper {
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */

        text-align: center;

        color: #000000;


        /* Inside Auto Layout */

        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 16px 0px;
    }
}

